<template>
  <!-- 照片对比动画组件 -->
  <div class="ai-collection">
    <div :id="`ai-collection-example${i}`" class="example-pic" :style="{width: styleWidth,height: styleHeight }" @mousedown="mousedown($event,i)" @mouseup="mouseup($event,i)" @mousemove="mousemove($event,i)" @touchstart="touchstart($event,i)" @touchend="touchend($event,i)" @touchmove="touchmove($event,i)">
      <div class="ori-pic-wrap" :style="{ width: posX + '%' }">
        <img class="ori-pic" :style="{width: styleWidth,height: styleHeight }" :src="$getFile(dataList.originImg)" alt="ori-pic" @load="imgLoad" />
      </div>
      <img :src="$getFile(dataList.resultImg)" :style="{ transform: `scale(${imgScal})`,width: styleWidth,height: styleHeight }" alt="result-pic" class="result-pic" />
      <div class="move-block" :style="{ left: posX + '%',height: styleHeight }">
        <img src="../assets/images/compliance-testing/move-btn@2x.png" alt="move-btn" class="move-btn" draggable="false" />
        <div class="col-line" :style="{ height: styleHeight }" />
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/common/utils";
export default {
  name: 'ImageComparison',
  props: {
    dataList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    i: {
      type: Number,
      default: 0
    },
    cycleTime: {
      type: Number,
      default: 3000,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    styleWidth: {
      type: String,
      default: utils.isMobile ? '7.5rem' : '385px',
    },
    styleHeight: {
      type: String,
      default: utils.isMobile ? '7.8rem' : '312px',
    }
  },
  data() {
    return {
      mouseIsEnter: false,
      posX: 0,
      posY: 0,
      Animation: false,
      currentIndex: 0,
      imgScal: 1,
    }
  },

  created() {
    if (!this.loop) this.posX = 50
  },

  methods: {
    imgLoad(e) {
      clearInterval(this.Interval)
      this.imgScal = 1
      if (this.loop) {
        this.posX = 0
        this.AnimationLoop()
      }
    },
    AnimationLoop() {
      this.timer = setTimeout(() => {
        this.currentIndex =
          this.currentIndex < this.dataList.length - 1
            ? this.currentIndex + 1
            : 0
        this.imgScal = 2
      }, this.cycleTime)
      this.Interval = setInterval(() => {
        if (this.posX < 100) {
          this.posX += 1
        } else {
          this.posX = 0
          clearInterval(this.Interval)
        }
      }, this.cycleTime / 100)
    },

    calc(event, i) {
      const _dom = document.querySelector(`#ai-collection-example${i}`)
      const { left, top, width, height } = _dom.getBoundingClientRect()
      console.log("🐅  _dom: >>>", _dom);
      const { clientX, clientY } = event

      const leftPer = parseFloat(((clientX - left) / width) * 100).toFixed(2)
      const rightPer = parseFloat(((clientY - top) / height) * 100).toFixed(2)

      this.posX = leftPer
      this.posY = rightPer
    },

    mousedown(event, i) {
      this.calc(event, i)

      this.mouseIsEnter = true
    },

    mouseup(event, i) {
      this.mouseIsEnter = false
    },

    mousemove(event, i) {
      if (!this.mouseIsEnter) {
        return
      }
      this.calc(event, i)
    },

    touchstart(event, i) {
      this.calc(event.targetTouches[0])

      this.mouseIsEnter = true
    },

    touchend(event, i) {
      this.mouseIsEnter = false
    },

    touchmove(event, i) {
      if (!this.mouseIsEnter) {
        return
      }
      this.calc(event.targetTouches[0], i)
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 980px) {
  .ai-collection {
    position: relative;

    .example-pic {
      position: relative;
      height: 312px;
      background: #d8d8d8;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 10;

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        z-index: 60;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }

        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          background: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .ai-collection {
    position: relative;

    .example-pic {
      flex: 0 0 7.5rem;
      position: relative;
      height: 7.8rem;
      overflow: hidden;
      border-radius: 0.16rem;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        overflow: hidden;
        z-index: 10;

        .ori-pic {
          width: 7.5rem;
          height: 7.8rem;
        }

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 7.8rem;
        z-index: 12;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }

        .move-help {
          position: absolute;
          top: 55%;
          right: 50%;
          width: 0.62rem;
          height: 0.88rem;
          animation: mymove 3s linear 0s infinite;
        }

        @keyframes mymove {
          0% {
            opacity: 1;
            right: 50%;
          }

          50% {
            opacity: 0.5;
            right: -75%;
          }

          100% {
            opacity: 1;
            right: 50%;
          }
        }

        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 7.8rem;
          background: #ffffff;
        }
      }
    }
  }
}
</style>
