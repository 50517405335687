<template>
  <!-- 主页为什么选择组件 -->
  <div class="whyChoose_container">
    <p class="title">{{ content.title }}</p>
    <p class="des">{{ content.des }}</p>
    <div class="why-area">
      <div class="item" v-for="(item, index) in content.resonList" :key="index">
        <p class="nums">{{ item.num }}</p>
        <p class="des">{{ item.des }}</p>
        <p class="explain">{{ item.explain }}</p>
        <div v-if="item.isExclusive" class="exclusive">Exclusive</div>
      </div>
    </div>

    <div class="whyChoose-mobile">
      <div class="whyChoose-mobile-swiper-box" v-if="loading">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in content.resonList" :key="index">
            <div :class="['introduce_item',`item-${index}`]">
              <div class="item_left">
                <p class="mini_title">{{ item.num }}</p>
                <p class="mini_des">{{ item.explain }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div v-if="content.resonList.length > 1" class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  des: 'WhyChoose',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      loading: false,
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
      }
    }
  },
  mounted() {

    this.$nextTick(() => {
      if (this.content) {
        setTimeout(() => {
          this.loading = true
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .whyChoose_container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 110px 0 90px;

    .title {
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
      background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .des {
      max-width: 1180px;
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-white);
      line-height: 24px;
      text-align: center;
    }

    .why-area {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 852px;
      gap: 20px;
      margin-top: 42px;

      .item {
        position: relative;
        height: 192px;
        padding: 20px 20px;
        border-radius: 10px;
        background: #c8bdff;
        box-sizing: border-box;
        overflow: hidden;
        transition: all 0.3s;
        &:hover {
          transform: translateY(-5px);
          -webkit-transform: translateY(-5px);
          -moz-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          -o-transform: translateY(-5px);
        }

        &:nth-child(1) {
          width: 316px;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-1.png")
              no-repeat center;
          }
        }

        &:nth-child(2) {
          width: 516px;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-2.png")
              no-repeat center;
          }
        }

        &:nth-child(3) {
          width: 244px;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-3.png")
              no-repeat center;
          }
        }

        &:nth-child(4) {
          width: 326px;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-4.png")
              no-repeat center;
          }
        }

        &:nth-child(5) {
          width: 242px;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-5.png")
              no-repeat center;
          }
        }

        .nums {
          position: relative;
          color: #fff;
          margin-bottom: 16px;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 120% */
        }

        .des {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }

        .explain {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }

        .exclusive {
          position: absolute;
          right: -30px;
          top: 20px;
          width: 126px;
          height: 30px;
          background: var(--theme-label);
          font-size: 16px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: var(--theme-primary-white);
          line-height: 30px;
          transform: rotate(45deg);
          text-align: center;
        }
      }
    }

    .whyChoose-mobile {
      display: none;
      overflow: hidden;
    }
  }
}

@media (max-width: 980px) {
  .whyChoose_container {
    width: 100%;
    margin-top: 0.9rem;
    padding: 0 0.32rem;
    box-sizing: border-box;
    .bg {
      display: none;
    }

    .title {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-family: Poppins;
      font-size: 0.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 0.48rem; /* 120% */
    }

    .des {
      margin: 0 auto;
      margin-top: 0.24rem;
      font-size: 0.28rem;
      font-family: Poppins-Regular, Poppins;
      color: #333;
      line-height: normal;
      text-align: center;
    }
    .why-area {
      display: none;
    }

    .whyChoose-mobile {
      margin-top: 0.68rem;

      .whyChoose-mobile-swiper-box {
        .swiper-container {
          width: 100%;
          margin: 0;
          box-shadow: none;
          border-radius: 0.24rem;
          background: #c8bdff;
        }
        .introduce_item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .item_left {
            padding: 0.3rem 0.2rem;
            min-height: 3rem;

            .mini_title {
              text-align: center;
              font-family: Poppins;
              font-size: 0.32rem;
              font-style: normal;
              font-weight: 600;
              line-height: 0.56rem; /* 175% */
              background: linear-gradient(
                123deg,
                #5255fe 34.24%,
                #b254ff 79.09%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .mini_des {
              color: #333;
              text-align: center;
              font-family: Poppins;
              font-size: 0.28rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 0.32rem;
            }
          }

          img {
            width: 2.8rem;
            height: 2.8rem;
            margin-top: 0.51rem;
          }
        }

        .item-0 {
          &::after {
            position: absolute;
            top: 0;
            right: -0.6rem;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-1.png")
              no-repeat center;
          }
        }

        .item-1 {
          &::after {
            position: absolute;
            top: 0;
            right: 0rem;
            width: 137%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-2.png")
              no-repeat center;
          }
        }

        .item-2 {
          &::after {
            position: absolute;
            top: 0;
            right: -1.2rem;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-3.png")
              no-repeat center;
          }
        }

        .item-3 {
          &::after {
            position: absolute;
            top: 0;
            right: -.6rem;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-4.png")
              no-repeat center;
          }
        }

        .item-4 {
          &::after {
            position: absolute;
            top: 0;
            right: -1.2rem;
            width: 100%;
            height: 100%;
            content: "";
            background: url("../assets/images/newimages/why-item-bg-5.png")
              no-repeat center;
          }
        }
        .swiper-pagination {
          padding: 0;
          margin-top: 0.32rem;

          ::v-deep .swiper-pagination-bullet {
            width: 0.2rem;
            height: 0.2rem;
            background-color: #eeecff;
          }

          ::v-deep .swiper-pagination-bullet-active {
            width: 1.92rem;
            height: 0.2rem;
            border-radius: 8px;
            opacity: 0.8;
            background-color: #7562f2;
          }
        }
      }
    }
  }
}
</style>