<template>
  <div class="rich_text" id="protocol">
    <div class="content">
      <div class="left-wrap">
        <h1>{{ content.title }}</h1>
        <p style="margin-bottom: 0.6rem">{{ content.des }}</p>
        <template v-for="(item, idx) in content.list">
          <div :key="`a` + idx" class="rich-item">
            <h2 v-show="item.title">{{ item.title }}</h2>
            <p class="rich-item-des" v-html="item.des"></p>
            <ul>
              <template v-for="(child, cIdx) in item.list">
                <li v-if="typeof child === 'string'" :key="`b` + cIdx">
                  {{ child }}
                </li>
                <template v-if="typeof child === 'object'">
                  <div class="rich-li-text" v-for="(li, lIdx) in child" :key="`c` + cIdx + lIdx">
                    <div v-html="li"></div>
                  </div>
                </template>
              </template>
            </ul>
          </div>
        </template>
        <template v-if="content.infos">
          <div class="infos-wrap" v-for="(item, idx) in content.infos" :key="idx">
            <p v-html="item"></p>
          </div>
        </template>
      </div>
      <div class="right-wrap">
        <div class="protocol-img">
          <img :src="$getFile(content.contentImg)" alt="">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "Protocol",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .rich_text {
    width: 100%;
    height: 630px;
    box-sizing: border-box;
    background: url(../assets/images/newimages/protocol-bg.png) no-repeat center;
    background-size: cover;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 90px;
      gap: 150px;
      .left-wrap {
        width: 686px;
        ::v-deep strong {
          font-family: Poppins !important;
          color: var(--theme-primary-black);
        }

        h1 {
          font-family: Poppins;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px; /* 140% */
          background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        p {
          font-size: 18px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: 28px;
        }

        h2 {
          color: var(--text-icon-font-gy-190-primary, rgba(0, 0, 0, 0.9));
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px; /* 125% */
          margin-top: 32px;
          margin-bottom: 16px;
        }

        .rich-item-des {
          color: #333;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }

        .rich-child-des {
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
        }

        ul {
          padding-left: 40px;

          li {
            font-size: 16px;
            line-height: 24px;
            margin-top: 30px;
            color: var(--theme-primary-black);
            list-style-type: disc;
          }

          .rich-li-text {
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
            color: var(--theme-primary-black);
          }

          .table {
            margin-top: 30px;
            margin-bottom: 60px;

            ::v-deep table {
              width: 100%;
              border: 1px solid #05021e;

              td {
                font-size: 16px;
                font-family: Poppins-Regular, Poppins;
                color: var(--theme-primary-black);
                line-height: 24px;
                padding: 10px;
                border: 1px solid #05021e;
              }
            }
          }
        }
      }
      .right-wrap {
        .protocol-img {
          width: 626px;
          height: 450px;
          img {
            width: 626px;
            height: 450px;
          }
        }
      }
    }
  }

  .infos-wrap {
    p {
      margin-top: 30px;
    }
  }
}

@media (max-width: 980px) {
  .rich_text {
    margin: 0.5rem auto 0;
    padding: 0 0.32rem 0.58rem;
    box-sizing: border-box;
    .content {
      .left-wrap {
        ::v-deep strong {
          font-family: Poppins !important;
          color: var(--theme-primary-black);
        }

        .guide-mobile-header {
          margin-bottom: 0.5rem;

          .bread-nav-box {
            padding: 0.2rem 0;

            .icon-home {
              width: 0.4rem;
              height: 0.4rem;
            }

            .arrow-right {
              width: 0.15rem;
              height: 0.22rem;
              margin-left: 0.23rem;
            }

            .bread-title {
              margin-left: 0.23rem;
              color: var(--theme-primary-black);
              font-size: 0.24rem;
              line-height: 0.36rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
            }
          }
        }

        h1 {
          font-family: Poppins;
          font-size: 0.4rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0.48rem;
        }

        p {
          font-size: 0.32rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: 0.48rem;
        }

        h2 {
          color: rgba(0, 0, 0, 0.9);
          font-family: Poppins;
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .rich-item {
          margin-bottom: 0.6rem;
        }

        .rich-item-des {
          color: #333;
          font-family: Poppins;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: .16rem;
        }

        .rich-child-des {
          font-size: 0.32rem;
          line-height: 0.48rem;
          margin-top: 0.24rem;
        }

        ul {
          padding-left: 0.3rem;

          li {
            font-size: 0.32rem;
            line-height: 0.48rem;
            margin-top: 0.32rem;
            color: var(--theme-primary-black);
            list-style-type: decimal;

            &:last-child {
              margin-left: -0.3rem;
              list-style-type: none;
            }
          }

          .rich-li-text {
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
            color: var(--theme-primary-black);
            display: flex;
            align-items: flex-start;

            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              flex-shrink: 0;
              color: var(--theme-primary-black);
              border-radius: 50%;
              margin-top: 8px;
              margin-right: 5px;
            }
          }

          .table {
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            width: 100%;
            overflow: auto;
            border: 1px solid #05021e;

            ::v-deep table {
              td {
                font-size: 16px;
                font-family: Poppins-Regular, Poppins;
                color: #05021e;
                line-height: 24px;
                padding: 10px;
                border-right: 1px solid #05021e;

                &:last-child {
                  border-right: none !important;
                }
              }
            }
          }
        }
      }

      .right-wrap {
        .protocol-img {
          width: 6.82rem;
          height: 4.93rem;
          img {
            width: 6.82rem;
            height: 4.93rem;
          }
        }
      }
    }
  }

  .infos-wrap {
    p {
      margin-top: 0.3rem;
    }
  }
}
</style>
