<template>
  <!-- 主页为什么选择组件 -->
  <div class="whyChoose">
    <p class="title">{{ content.title }}</p>
    <p class="des">{{ content.des }}</p>

    <div class="why-area">
      <div class="why-item">
        <p :class="['explain',imgNuber == index ?'explain-active':'']" v-for="(item, index) in content.resonList" :key="index" @mousemove="handleMousemove(index)" @mouseleave="handleMouseover">{{ item.explain }}</p>
      </div>

      <img class="explain-img" :src="$getFile(content.resonList[imgNuber].img)" />
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils'
export default {
  name: 'GridList',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {
      imgNuber: 0,
    }
  },
  mounted() {
    // 初始图片为第一个
    this.imgNuber = 0;
    // 每3秒切换一次图片，如果mousemove事件触发，则切换到对应图片,并重新计时
    this.timer = setInterval(() => {
      this.imgNuber = (this.imgNuber + 1) % this.content.resonList.length
    }, 3000)
  },
  methods: {
    handleMousemove(index) {
      this.imgNuber = index
      clearInterval(this.timer)
      if (utils.isMobile()) {
        this.handleMouseover()
      }
    },
    handleMouseover() {
      this.timer = setInterval(() => {
        this.imgNuber = (this.imgNuber + 1) % this.content.resonList.length
      }, 3000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .whyChoose {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;

    .title {
      max-width: 1180px;
      font-size: 48px;
      font-family: Poppins;
      color: rgba(0, 0, 0, 0.9);
      line-height: 56px;
      margin-bottom: 12px;
      font-weight: 600;
      text-align: center;
    }

    .des {
      max-width: 1180px;
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      color: #ffffff;
      text-align: center;
      line-height: 24px;
    }

    .why-area {
      width: 1215px;
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      .why-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .explain {
          width: 356px;
          padding: 20px;
          border-radius: 8px;
          box-sizing: border-box;
          background: #e7e4ff;
          color: #8862fa;
          font-family: Poppins;
          font-size: 22.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 28.125px; /* 125% */
          text-align: center;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            color: #fff;
            border-radius: 8px;
            background: linear-gradient(108deg, #a496ff 1.75%, #a8c4ff 93.17%);
          }
        }
        .explain-active {
          color: #fff;
          background: linear-gradient(108deg, #a496ff 1.75%, #a8c4ff 93.17%);
        }
      }

      .explain-img {
        width: 828px;
        height: 464px;
      }
    }
  }
}

@media (max-width: 980px) {
  .whyChoose {
    width: 100%;
    padding-bottom: 0.68rem;
    margin-top: 0.5rem;

    .title {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-family: Poppins;
      font-size: 0.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.48rem; /* 120% */
      padding: 0 0.32rem;
    }

    .des {
      width: 6.86rem;
      font-size: 0.28rem;
      font-family: Poppins-Regular, Poppins;
      color: #000;
      line-height: 0.36rem;
      text-align: center;
      margin: 0 auto;
    }

    .why-area {
      margin-top: 0.68rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .why-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0.24rem;

        .explain {
          display: flex;
          width: 3.24rem;
          padding: 0.2rem 0.1rem;
          justify-content: center;
          align-items: center;
          gap: 0.1rem;
          white-space: nowrap;
          color: #8862fa;
          text-align: center;
          font-family: Poppins;
          font-size: 0.22rem;
          font-style: normal;
          font-weight: 600;
          line-height: 0.28rem; /* 127.273% */
          border-radius: 0.08rem;
          background: #e7e4ff;
        }
        .explain-active {
          border-radius: 0.08rem;
          color: #fff;
          background: linear-gradient(108deg, #a496ff 1.75%, #a8c4ff 93.17%);
        }
      }

      .explain-img {
        width: 6.86rem;
        height: 3.84rem;
        margin-top: 0.48rem;
      }
    }
  }
}
</style>