<template>
  <!-- H5登录弹窗 -->
  <div class="mobile-header-container">
    <div class="mobile-kefu">
      <kefu ref="kefu" :hideKefu="content.hideKefu || false" />
    </div>
    <div class="mobile-header">
      <div class="header_content">
        <a class="nav_left" href="/">
          {{ content.title }}
        </a>
        <img
          class="icon-right-open"
          v-show="!showRightPop"
          :src="$getFile('ui/icon-top_open.webp')"
          @click="openSlide"
          alt="open-menu"
        />
        <img
          class="icon-right-open"
          v-show="showRightPop"
          :src="$getFile('ui/icon-top_close.webp')"
          @click="closeSlide"
          alt="close-menu"
        />
      </div>
    </div>
    <div
      class="mobile_slide_container"
      :style="{
        width: showRightPop ? '100%' : '0%',
        opacity: showRightPop ? 1 : 0,
      }"
      @click.stop=""
    >
      <div class="mask" @click="showRightPop = !showRightPop"></div>
      <div
        class="slider"
        :style="{
          transform: showRightPop ? 'translateX(0%)' : 'translateX(100%)',
        }"
      >
        <div class="slider_header row">
          <div>
            {{ content.title }}
          </div>
          <login-wrap />
        </div>
        <div class="slider_content">
          <div
            class="content_item"
            v-for="(items, index) in menuList"
            :key="index"
          >
            <div class="tools-title" @click="openSubItem(index)">
              {{ items.title }} {{ afterBrand
              }}<span :class="['icon_arrow', { is_open: items.isOpen }]" />
            </div>
            <div class="feature_list" v-show="items.isOpen">
              <div v-for="(item, index) in items.list" :key="index">
                <a :href="item.url" class="feature_item row">
                  <div class="feature-text">
                    {{ item.title
                    }}<span class="tag" v-if="item.tag">{{ item.tag }}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="content_item">
            <div class="tools-title">
              <span @click="openKefu">{{ $t('support') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kefu from "./ui/kefu.vue";

export default {
  components: { kefu },

  props: {
    afterBrand: {
      type: String,
      default: "",
    },
    beforeBrand: {
      type: String,
      default: "",
    },
    content: {
      typeof: Object,
      default: () => {},
    },
    siteType: {
      type: Number,
      default: 1,
    },
    navBarList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      menuList: [],
      showRightPop: false,
      scrollPosition: 0,
    };
  },
  computed: {
    routerPath() {
      return this.$route.path;
    },
  },
  mounted() {
    let items = [];
    Object.keys(this.navBarList).forEach((res) => {
      this.navBarList[res].list = this.navBarList[res].list.map((item) => {
        if (Number(item.type) === Number(this.siteType)) {
          item.url = window.location.origin;
        }
        return item;
      });
      items.push(this.navBarList[res]);
    });
    this.menuList = items;
  },
  watch: {
    routerPath: {
      handler(newVal, oldVal) {
        this.showRightPop = false;
      },
      immediate: true,
    },
    showRightPop: {
      handler(newVal, oldVal) {
        if (newVal) {
          const $body = document.querySelector("body");
          const scrollPosition = window.pageYOffset;
          $body.style.overflow = "hidden";
          $body.style.position = "fixed";
          $body.style.top = `-${scrollPosition}px`;
          $body.style.width = "100%";
          this.scrollPosition = scrollPosition;
        } else {
          const $body = document.querySelector("body");
          $body.style.removeProperty("overflow");
          $body.style.removeProperty("position");
          $body.style.removeProperty("top");
          $body.style.removeProperty("width");
          window.scrollTo(0, this.scrollPosition);
        }
      },
    },
  },
  methods: {
    openKefu() {
      this.$refs.kefu.openPoup();
    },
    toLink(name) {
      this.showRightPop = false;
      if (name === "login") {
        this.$store.commit("changeDialog", {
          name: "FormLayout",
          active: "Login",
        });
        return;
      }
      this.$router.push({ name });
    },
    openSlide() {
      this.showRightPop = true;
      // this.scrollFill = 'shadow'
      this.$emit("open", this.showRightPop);
    },
    closeSlide() {
      this.scrollFill = "";
      this.showRightPop = false;
      this.$emit("close", this.showRightPop);
    },
    openSubItem(key) {
      this.menuList = this.menuList.map((res, index) => {
        if (index === key) {
          res.isOpen = !res.isOpen;
        }
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 981px) {
  .mobile-kefu {
    :deep(.kefu-title) {
      display: none;
    }
  }
  .mobile-header {
    position: fixed;
    top: 0;
    width: 7.5rem;
    height: 1.16rem;
    padding: 0.34rem 0.32rem;
    border-bottom: 1px solid #efefef;
    background: #fff;
    box-sizing: border-box;
    z-index: 900;
    .header_content {
      width: 6.86rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-icon {
        width: 1.98rem;
        height: 0.48rem;
      }
      .icon-right-open {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
  .nav_left {
    color: #000;
    font-size: 0.4rem;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
  }
  .mobile_slide_container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1013;
    height: 100vh;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    .mask {
      z-index: 1011;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
    .slider {
      position: absolute;
      transform: translateX(100%);
      right: 0;
      height: 100%;
      width: 5.1rem;
      padding: 0 0.3rem;
      background-color: white;
      transition: all 0.3s ease-in-out;
      z-index: 1012;
      box-shadow: 0rem 0.02rem 0.24rem 0rem rgba(156, 155, 166, 0.4);
      overflow-y: auto;

      .slider_header {
        position: relative;
        margin: 0 auto;
        padding: 0.52rem 0.3rem 0.4rem;
        padding-bottom: 0.2rem;
        border-bottom: 0.01rem solid #dbd3fe;
        box-sizing: border-box;
        font-size: 0.4rem;
        padding-left: 0;
        padding-right: 0;
        .avatar {
          width: 2.38rem;
          height: 0.6rem;
        }
        :deep(.flex-box) {
          display: flex;
          justify-content: flex-end;
          font-size: 0.2rem;
          gap: 10px;
          .login-btn {
            line-height: normal !important;
            border: 2px solid #4581ff;
            padding: 0.08rem 0.16rem;
            color: #4581ff;
            font-weight: bold;
            border-radius: 0.08rem;
          }
        }
      }
      .slider_content {
        padding-bottom: 2rem;
        .content_item {
          position: relative;
          .tools-title {
            display: block;
            position: relative;
            padding: 0.3rem 0rem;
            font-size: 0.28rem;
            font-family: Inter;
            font-weight: 500;
            color: #05021e;
            line-height: 0.32rem;

            &:active {
              background-color: #f4f2ff;
            }
            .icon_arrow {
              position: absolute;
              top: 50%;
              right: 0.3rem;
              transform: translate(0, -50%) rotate(0);
              display: block;
              width: 0.2rem;
              height: 0.2rem;
              background: url("@/assets/images/ui/icon-trangle-1.webp")
                no-repeat center center;
              background-size: 100% 100%;
              transition: all 0.3s linear;
              &.is_open {
                transform: translate(0, -50%) rotate(180deg);
              }
            }
          }
          .feature_list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            .feature_item {
              padding: 0.26rem 0rem 0.26rem;
              // width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .tag {
                margin-left: 0.1rem;
                color: #fff;
                font-size: 0.2rem;
                font-weight: 900;
                border-radius: 0.04rem;
                background: #f46868;
                padding: 0.04rem 0.1rem;
                text-transform: uppercase;
              }
              &:active {
                background-color: #f4f2ff;
              }
              .feature-item-img {
                width: 0.48rem;
                height: 0.48rem;
              }
              .feature-text {
                margin-left: 0.16rem;
                font-size: 0.28rem;
                font-family: Inter;
                font-weight: 400;
                color: #05021e;
                line-height: 0.32rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>