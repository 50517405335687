const defaultTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#3f4e59",
  "--theme-primary-suffix-hover": "#3d4959",
  //价格 tag
  "--theme-primary-price-tag": "#3f4e59",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#3f4e59",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const blueTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #00BFFF 0%, #1E90FF 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #00BFFF 0%, #1E90FF 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#1a98ff",
  "--theme-primary-suffix-hover": "#0578fd",
  //价格 tag
  "--theme-primary-price-tag": "#1a98ff",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#1681fd",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const gradientTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(128deg, #b254ff 3.14%, #5255fe 96.86%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #00BFFF 0%, #1E90FF 100%)",
  // 小模块背景色
  "--theme-primary-wrap": "#eeecff",
   // 小模块背景色-高亮
   "--theme-primary-wrap-light": "#ddddff",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#7a55ff",
  "--theme-primary-suffix-hover": "#0578fd",
  //价格 tag
  "--theme-primary-price-tag": "#1a98ff",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#0d0d0e",
  //输入框聚焦
  "--theme-focus": "#7a55ff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const peachTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #FFDAB9 0%, #FFA07A 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #FFDAB9 0%, #FFA07A 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#fc976c",
  "--theme-primary-suffix-hover": "#fc8653",
  //价格 tag
  "--theme-primary-price-tag": "#fc976c",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#fc976c",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const pinkTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(90deg, #FFC09B 0%, #FFB6C1 50%, rgba(159, 201, 253, 0.80) 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #FFC0CB 0%, #FFB6C1 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#f78899",
  "--theme-primary-suffix-hover": "#fc7b44",
  //价格 tag
  "--theme-primary-price-tag": "#f78899",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#f78899",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const orangeTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #FFD700 0%, #FF8C00 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #FFD700 0%, #FF8C00 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#ff9100",
  "--theme-primary-suffix-hover": "#fc773f",
  //价格 tag
  "--theme-primary-price-tag": "#ff9100",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#ff9100",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

const terreTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #D2B48C 0%, #C19A6B 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #D2B48C 0%, #C19A6B 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#c39e6f",
  "--theme-primary-suffix-hover": "#fc743a",
  //价格 tag
  "--theme-primary-price-tag": "#c39e6f",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#c39e6f",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}

export const theme = {
  // 默认碳灰色
  defaultTheme,
  // 蓝色
  blueTheme,
  // 桃色
  peachTheme,
  // 粉色
  pinkTheme,
  // 橘黄
  orangeTheme,
  // 大地
  terreTheme,
  // 渐变
  gradientTheme
}