<template>
  <div class="kefu-container" v-if="showPoup" id="kefu">
    <div class="kefu-title" @click="openPoup">{{ $t('support') }}</div>
    <Popup ref="popup" />
  </div>
</template>

<script>
import Popup from "./Pop-up.vue";
export default {
  name: "Banner",
  props: {
    hideKefu: {
      type: Boolean,
      default: false
    },
  },
  components: { Popup },
  computed: {
    showPoup() {
      if (this.hideKefu) {
        return false;
      }
      const domains = ["virtutoon", "soundtxt", "vocaltxt", "pdffilehub"];
      const host = window.location.host.replace("www.", "");
      return !domains.some((domain) => host.includes(domain));
    },
  },
  data() {
    return {
      supportName: {
        en: 'Support',
        id: 'Dukungan'
      }
    };
  },
  mounted() { },
  methods: {
    openPoup() {
      this.$refs.popup.openDialog();
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 980px) {
  .kefu-title {
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

@media (max-width: 981px) {
  .kefu-title {
    cursor: pointer;
  }
}
</style>