<template>
  <div class="swiper" id="certify" v-if="content.length > 0">
    <div class="swiper-container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in content" :key="index">
          <div class="swiper-slide-box">
            <div class="swiper-content-box">{{ item.content }}</div>
            <div class="user-info">
              <img class="user-icon" loading="lazy" :alt="item.name" :src="$getFile(item.icon)" />
              <div class="user-info-box">
                <div class="user-info-name">{{ item.name }}</div>
                <div class="user-info-des">{{ item.des }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
// 引入插件
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
import utils from '@/common/utils'
export default {
  props: {
    content: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  mounted() {
    let options = {
      slidesPerView: 3,
      spaceBetween: 110,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      slideShadows: true, //是否开启slide阴影
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }
    if (utils.isMobile()) {
      options = {
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
    }
    this.$nextTick(() => {
      new Swiper(this.$refs.swiperContainer, options)
    })
  },
  data() {
    return {
      swiper: null,
    }
  },
}
</script>

<style lang="scss">
@media (min-width: 980px) {
  .swiper {
    width: 100%;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    transition: 300ms;
    transform: scale(0.6);
    .swiper-slide-box {
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 50px 80px 0px #def6f7;
      padding: 60px 70px;
      width: 718px;
      opacity: 0.2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .swiper-content-box {
        color: #021222;
        text-align: center;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-height: 100px;
        padding-bottom: 32px;
      }
    }
    .user-info {
      display: flex;
      justify-content: center;
      align-content: center;
      .user-icon {
        border-radius: 68px;
        width: 68px;
        height: 68px;
        margin-right: 16px;
      }
      .user-info-box {
        color: #021222;
        text-align: left;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        line-height: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        .user-info-name {
          font-weight: 600;
        }
        .user-info-des {
          font-family: Manrope;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
  .swiper-slide .swiper-slide-box{
    box-shadow: none;
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    position: relative;
    z-index: 11;
    .swiper-slide-box {
      opacity: 1;
    }
  }
  .swiper-pagination {
    position: relative !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 1;
      border-radius: 20px;
      cursor: pointer;
      transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add a smooth transition */
      &:hover {
        background: #7562f2;
      }
    }
    .swiper-pagination-bullet-active {
      border-radius: 20px;
      background: #7562f2;
      opacity: 1;
      width: 96px;
    }
  }
}

@media (max-width: 980px) {
  .swiper {
    width: 100%;
    background: #d5daff;
    padding-top: 0.8rem;
  }
  .swiper-container {
    width: calc(100% - 0.96rem);
    margin: 0 0.48rem;
  }
  .swiper-slide {
    transition: 300ms;
    transform: scale(0.6);
    .swiper-slide-box {
      border-radius: 0.4rem;
      background: #faffff;
      padding: 0.6rem 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .user-info {
      display: flex;
      justify-content: center;
      align-content: center;
      .user-icon {
        border-radius: 100%;
        width: 1.36rem;
        height: 1.36rem;
        margin-right: 0.32rem;
      }
      .user-info-box {
        text-align: left;
        color: #0f1616;
        font-family: Roboto;
        font-style: normal;
        line-height: normal;
        margin-top: 0.15rem;
        .user-info-name {
          font-weight: 600;
          font-size: 0.32rem;
        }
        .user-info-des {
          margin-top: 0.1rem;
          color: #979797;
          font-family: Poppins;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .swiper-content-box {
      color: #021222;
      font-family: Poppins;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.56rem;
    }
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    position: relative;
    border-radius: 0.4rem;
    z-index: 11;
    .swiper-slide-box {
      opacity: 1;
    }
  }
  .swiper-pagination {
    position: relative !important;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0 .6rem;
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 1;
      cursor: pointer;
      border-radius: 2rem;
      transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add a smooth transition */
      &:hover {
        background: #7562f2;
      }
    }
    .swiper-pagination-bullet-active {
      border-radius: 2rem;
      background: #7562f2;
      opacity: 1;
      width: 1.92rem;
    }
  }
}
</style>