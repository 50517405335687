<template>
  <!-- 用户评论组件 -->
  <div class="discuss_container">
    <div class="content">
      <div class="discuss_title">{{ content.title }}</div>
      <div class="discuss_desc">{{ content.desc }}</div>
      <div class="discuss_component">
        <SwiperBox :content="content.list" />
      </div>
    </div>
  </div>
</template>

<script>
import SwiperBox from '@/components/ui/SwiperBox.vue'

export default {
  name: 'DiscussComponents',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  components: {
    SwiperBox
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 10,
        loop: true,
        normalizeSlideIndex: true,
        loopAdditionalSlides: 3,
        slidesOffsetBefore: 25,
        slidesPerview: 2,
        slidesOffsetAfter: -30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 300000,
          disableOnInteraction: false
        },
      },
      swiperOption1: {
        spaceBetween: -40,
        loop: true,
        loopAdditionalSlides: 3,
        slidesOffsetBefore: 25,
        slidesPerview: 2,
        slidesOffsetAfter: -30,
        autoplay: {
          delay: 300000,
          disableOnInteraction: true
        },
      },
      commonsList: [],
      activeIndex: 0,
      activeIndex1: 0
    }
  },
  mounted() {
    for (let i = 0; i < this.content.list.length; i += 3) {
      this.commonsList.push(this.content.list.slice(i, i + 3))
    }
  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .discuss_container {
    width: 100%;
    background: linear-gradient(
      95deg,
      #f3f1fd 1.18%,
      #d9dafe 70.78%,
      #cbe7fe 98.82%
    );
    padding: 90px 0;

    .content {
      .discuss_title {
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px; /* 116.667% */
      }

      .discuss_desc {
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        color: #ffffff;
        line-height: 24px;
      }

      .discuss_component {
        max-width: 1248px;
        margin: 0 auto;
        margin-top: 80px;
      }
    }
  }
}

@media (max-width: 980px) {
  .discuss_container {
    padding: 0.8rem 0 0;
    text-align: center;

    .content {
      .discuss_title {
        color: #020a22;
        text-align: center;
        font-family: Manrope;
        font-size: 0.48rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .discuss_desc {
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.36rem;
      }
      .discuss_component {
        margin-top: .6rem;
      }
    }
  }
}
</style>