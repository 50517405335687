<template>
  <!-- 问题组件 -->
  <div class="question-container">
    <component :is="content.tag || 'h2'" class="title">{{ content.title }}</component>
    <div class="question_list">
      <div class="question_wrap">
        <div :class="['question_item', activeLIdx === idx && 'question_item_active']" v-for="(item, idx) in content.questionLeft" :key="idx">
          <div :class="['question_top', activeLIdx === idx && 'active']" @click="checkedActiveL(idx)">
            <div class="item-title" :style="{ color: activeLIdx === idx? 'var(--theme-focus)' : '' }">
              <div class="number_wrap">{{ idx+1 }}</div>
              <div class="title_wrap">{{ item.title }}</div>
            </div>
            <img class="item_icon" :style="{ transform: activeLIdx === idx ? 'rotate(-0)' : 'rotate(-180deg)' }" :src="$getFile('newimages/down_arrow.svg')" alt="">
          </div>
          <template v-for="(des, dIdx) in item.desList">
            <div ref="defaultL" class="default_des" :key="'d' + dIdx">
              <div ref="questionL" :class="['question_des', activeLIdx === idx && 'question-active']">
                <span class="des_warp" v-html="des.des" />
                <div class="des_warp" v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" />
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="question_wrap">
        <div :class="['question_item', activeRIdx === idx && 'question_item_active']" v-for="(item, idx) in content.questionReft" :key="idx">
          <div :class="['question_top', activeRIdx === idx && 'active']" @click="checkedActiveR(idx)">
            <div class="item-title" :style="{ color: activeRIdx === idx? 'var(--theme-focus)' : '' }">
              <div class="number_wrap">{{ idx+content.questionLeft.length+1 }}</div>
              <div class="title_wrap">{{ item.title }}</div>
            </div>
            <img class="item_icon" :style="{ transform: activeRIdx === idx ? 'rotate(-0)' : 'rotate(-180deg)' }" :src="$getFile('newimages/down_arrow.svg')" alt="">
          </div>
          <template v-for="(des, dIdx) in item.desList">
            <div ref="defaultR" class="default_des" :key="'d' + dIdx">
              <div ref="questionR" :class="['question_des', activeRIdx === idx && 'question-active']">
                <span v-html="des.des" />
                <div class="des_warp" v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="question_list_h5">
      <swiper class="question_swiper" :options="swiperOption">
        <swiper-slide>
          <div class="question_wrap">
            <div :class="['question_item', activeLIdx2 === idx && 'question_item_active']" v-for="(item, idx) in content.questionLeft" :key="idx">
              <div :class="['question_top', activeLIdx2 === idx && 'active']" @click="checkedActiveL2(idx)">
                <div class="item-title" :style="{ color: activeLIdx2 === idx? 'var(--theme-focus)' : '' }">
                  <div class="number_wrap">{{ idx+1 }}</div>
                  <div class="title_wrap">{{ item.title }}</div>
                </div>
                <img class="item_icon" :style="{ transform: activeLIdx2 === idx ? 'rotate(-0)' : 'rotate(-180deg)' }" :src="$getFile('newimages/down_arrow.svg')" alt="">
              </div>
              <template v-for="(des, dIdx) in item.desList">
                <div ref="defaultL2" class="default_des" :key="'d' + dIdx">
                  <div ref="questionL2" :class="['question_des', activeLIdx2 === idx && 'question-active']">
                    <span class="des_warp" v-html="des.des" />
                    <div class="des_warp" v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="question_wrap">
            <div :class="['question_item', activeRIdx2 === idx && 'question_item_active']" v-for="(item, idx) in content.questionReft" :key="idx">
              <div :class="['question_top', activeRIdx2 === idx && 'active']" @click="checkedActiveR2(idx)">
                <div class="item-title" :style="{ color: activeRIdx2 === idx? 'var(--theme-focus)' : '' }">
                  <div class="number_wrap">{{ idx+content.questionLeft.length+1 }}</div>
                  <div class="title_wrap">{{ item.title }}</div>
                </div>
                <img class="item_icon" :style="{ transform: activeRIdx2 === idx ? 'rotate(-0)' : 'rotate(-180deg)' }" :src="$getFile('newimages/down_arrow.svg')" alt="">
              </div>
              <template v-for="(des, dIdx) in item.desList">
                <div ref="defaultR2" class="default_des" :key="'d' + dIdx">
                  <div ref="questionR2" :class="['question_des', activeRIdx2 === idx && 'question-active']">
                    <span v-html="des.des" />
                    <div class="des_warp" v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </swiper-slide>
        <div class="question-swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

  </div>
</template>

<script>
export default {
  name: 'QuestionsList',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      activeLIdx: '',
      activeRIdx: '',
      activeLIdx2: '',
      activeRIdx2: '',
      swiperOption: {
        autoplay:{
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.question-swiper-pagination'
        }
      }
    }
  },
  onLoad() {
  },
  methods: {
    checkedActiveL(idx) {
      this.activeLIdx = this.activeLIdx === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.defaultL.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeLIdx === idx) {
        const activeEl = this.$refs.defaultL[idx]
        activeEl.style.height = this.$refs.questionL[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    },

    checkedActiveR(idx) {
      this.activeRIdx = this.activeRIdx === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.defaultR.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeRIdx === idx) {
        const activeEl = this.$refs.defaultR[idx]
        activeEl.style.height = this.$refs.questionR[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    },

    checkedActiveL2(idx) {
      this.activeLIdx2 = this.activeLIdx2 === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.defaultL2.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeLIdx2 === idx) {
        const activeEl = this.$refs.defaultL2[idx]
        activeEl.style.height = this.$refs.questionL2[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    },

    checkedActiveR2(idx) {
      this.activeRIdx2 = this.activeRIdx2 === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.defaultR2.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeRIdx2 === idx) {
        const activeEl = this.$refs.defaultR2[idx]
        activeEl.style.height = this.$refs.questionR2[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .question-container {
    padding: 100px 0;

    .title {
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      color: var(--theme-primary-black);
      line-height: 56px; /* 116.667% */
      margin: 0 0 80px;
    }

    .question_list {
      display: flex;
      width: 1132px;
      margin: 0 auto;
      gap: 32px;
      .question_wrap {
        width: 550px;
        .question_item {
          border-radius: 16px;
          background: #f3f3ff;
          margin-bottom: 32px;

          .question_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
            gap: 30px;
            transition: all 200ms;
            cursor: pointer;

            .item-title {
              display: flex;
              align-items: center;
              .title_wrap {
                color: #021222;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 120% */
              }

              &:hover {
                .title_wrap {
                  color: var(--theme-primary-suffix);
                }
              }

              .number_wrap {
                display: inline-block;
                min-width: 34px;
                height: 34px;
                text-align: center;
                margin-right: 20px;
                color: #fff;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 34px; /* 140% */
                border-radius: 50%;
                background: var(--theme-primary-suffix);
              }
            }

            .item_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              height: auto;
              text-align: center;
              cursor: pointer;
              width: 16px;
              height: 16px;
              transition: all 200ms;
            }
          }

          .active {
            padding-bottom: 19px;

            .item-title {
              color: var(--theme-primary-suffix);
            }
          }

          .default_des {
            height: 0;
            overflow: hidden;
            transition: all 0.25s ease-out;

            .question_des {
              padding: 0 30px;
              display: block;
              opacity: 0;
              font-size: 16px;
              font-weight: 400;
              font-family: Poppins-Regular, Poppins;
              color: var(--theme-font-question-tiny);
              line-height: 24px;
              box-sizing: border-box;
              transition: all 0.25s ease-out;
              .des_warp {
                color: #021222;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }

            .question-active {
              opacity: 1 !important;
            }
          }
        }
        .question_item_active {
          background: #ddddff;
        }
      }
    }
    .question_list_h5 {
      display: none;
    }
  }
}

@media (max-width: 980px) {
  .question-container {
    -webkit-tap-highlight-color: transparent;
    padding: 0.9rem 0rem;

    .title {
      margin-bottom: 0.6rem;
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 0.48rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.48rem; /* 100% */
    }
    .question_list {
      display: none;
    }

    .question_list_h5 {
      .swiper_box {
        width: 7.5rem;
      }
      .question_wrap {
        .question_item {
          border-radius: 0.16rem;
          background: #f3f3ff;
          margin-bottom: 0.32rem;

          .question_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.6rem;
            gap: 0.32rem;
            transition: all 200ms;
            cursor: pointer;

            .item-title {
              display: flex;
              align-items: center;
              .title_wrap {
                color: #021222;
                font-family: Poppins;
                font-size: 0.28rem;
                font-style: normal;
                font-weight: 500;
                line-height: 0.48rem; /* 171.429% */
              }

              .number_wrap {
                display: inline-block;
                min-width: 0.68rem;
                height: 0.68rem;
                text-align: center;
                margin-right: 0.4rem;
                color: #fff;
                font-family: Roboto;
                font-size: 0.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 0.68rem; /* 140% */
                border-radius: 50%;
                background: var(--theme-primary-suffix);
              }
            }

            .item_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              height: auto;
              text-align: center;
              cursor: pointer;
              width: 0.4rem;
              height: 0.4rem;
              transition: all 200ms;
            }
          }

          .active {
            padding-bottom: 0.32rem;

            // .item-title {
            //   color: var(--theme-primary-suffix);
            // }
          }

          .default_des {
            height: 0;
            overflow: hidden;
            transition: all 0.25s ease-out;

            .question_des {
              padding: 0 0.6rem;
              display: block;
              opacity: 0;
              box-sizing: border-box;
              transition: all 0.25s ease-out;
              color: #021222;
              font-family: Poppins;
              font-size: 0.28rem;
              font-style: normal;
              font-weight: 400;
            }

            .question-active {
              opacity: 1 !important;
            }
          }
        }
        .question_item_active {
          background: #ddddff;
        }
      }
      .question-swiper-pagination {
        padding: 0;
        line-height: .3rem;
        text-align: center;
        ::v-deep .swiper-pagination-bullet {
          width: 0.2rem;
          height: 0.2rem;
          background-color: #eeecff;
        }

        ::v-deep .swiper-pagination-bullet-active {
          width: 1.92rem;
          height: 0.2rem;
          border-radius: 8px;
          opacity: 0.8;
          background-color: #7562f2;
        }
      }
    }
  }
}
</style>
