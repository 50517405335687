<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="swiper-box">
      <swiper ref="swiper" :options="swiperOptions2">
        <swiper-slide v-for="(item, index) in content.list" :key="index">
          <div class="banner-box">
            <div class="banner-left">
              <h1 v-html="item.title"></h1>
              <p>{{ item.des }}</p>
            </div>
            <div class="banner-right">
              <img :src="$getFile(item.img)" alt="">
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div v-if="content.list.length > 1" class="swiper-pagination2"></div>
    </div>
  </div>
</template>

<script>
import SwiBanner from '@/components/ui/SwiBanner.vue'
export default {
  name: 'SwiperBanner',
  components: { SwiBanner },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {
      activeIdx: 0,
      swiperOptions2: {
        // 无限
        loop: true,
        // 自动轮播图
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination2",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
      }
    }
  },
  computed: {
    topEleBg() {
      return "#FFF"
    }
  },
  methods: {
    // swiperSwitch(idx) {
    //   this.$refs.swiper.swiper.slideTo(idx + 1)
    // }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    overflow: hidden;
    height: 540px;
    .banner-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 56px 174px;
      box-sizing: border-box;
      max-width: 1552px;
      width: 100%;
      background: var(--theme-primary);
      border-radius: 26px;
      background: linear-gradient(94deg, #fdd8ff 0.84%, #bcecff 99.16%);
      margin: 50px 0;

      .banner-left {
        width: 627px;
        height: auto;
        cursor: default;

        h1 {
          font-size: 56px;
          font-family: Poppins;
          color: var(--theme-primary-black);
          line-height: 72px;
          margin-bottom: 24px;
          ::v-deep .ttt {
            background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        p {
          font-size: 20px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: 30px;
        }
      }

      .banner-right {
        width: 486px;
        height: 378px;
        margin-left: 67px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .swiper-slide .swiper-slide-box {
      box-shadow: none;
    }
    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      transform: scale(1);
      position: relative;
      z-index: 11;
      .swiper-slide-box {
        opacity: 1;
      }
    }
    ::v-deep .swiper-pagination2 {
      position: relative !important;
      display: flex;
      align-items: center;
      padding: 18px 20px;
      border-radius: 79px;
      background: rgba(0, 0, 0, 0.18);
      gap: 10px;
      margin: 0 auto;
      bottom: 120px;
      width: 110px;
      z-index: 999;
      .swiper-pagination-bullet {
        background: #fff;
        opacity: 0.62;
        border-radius: 20px;
        cursor: pointer;
        width: 10px;
        height: 10px;
        transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add a smooth transition */
        &:hover {
          background: #fff;
          opacity: 1;
        }
      }
      .swiper-pagination-bullet-active {
        border-radius: 20px;
        background: #fff;
        opacity: 1;
        width: 18px;
        height: 10px;
      }
    }
  }
}

@media (max-width: 980px) {
  .banner-container {
    .banner-box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.6rem 0.32rem;
      background: linear-gradient(94deg, #fdd8ff 0.84%, #bcecff 99.16%);
      border-radius: 0.2rem;
      margin-top: 0.32rem;
      box-sizing: border-box;
      min-height: 11rem;

      .banner-left {
        margin: 0 auto;
        text-align: center;

        h1 {
          color: #000029;
          font-family: Poppins;
          font-size: 0.56rem;
          font-style: normal;
          font-weight: 700;
          line-height: 0.68rem;
          margin-bottom: 0.32rem;
          text-align: left;
        }

        p {
          color: #05021e;
          font-family: Poppins;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.36rem; /* 128.571% */
          text-align: left;
        }
      }

      .banner-right {
        position: absolute;
        bottom: 0.6rem;
        height: 5.03rem;
        margin: 0 auto;
        padding: 0.32rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .swiper-pagination2 {
      display: none;
    }
  }
}
</style>