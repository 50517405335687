<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <img class="banner_bg" :src="$getFile(content.bgImg)" alt="" />
    <img class="banner_h5_bg" :src="$getFile(content.bgImgH5)" alt="" />
    <div class="banner-box">
      <div class="banner-left">
        <h1 class="title">{{ content.title }}</h1>
        <p class="des">{{ content.des }}</p>
      </div>
      <div class="banner-right">
        <img :src="$getFile(content.useImg)" v-if="content.useImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPhone",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    let that = this;
    return {
      activeIdx: 0,
      swiperOptions: {
        initialSlide: 0,
        loop: false,
        effect: "fade",
        speed: 200,
        autoplay: {
          disableOnInteraction: false,
        },
        on: {
          transitionStart() {
            that.activeIdx = this.realIndex;
          },
        },
      },
    };
  },
  computed: {
    topEleBg() {
      return this.$store.state.topEleBg;
    }
  },
  methods: {
    swiperSwitch(idx) {
      this.$refs.swiper.swiper.slideTo(idx + 1);
    },

    SetPoint() {
      this.$api.saveOperationLog("Download_win_btncli", {
        source: this.source || "home",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    position: relative;
    overflow: hidden;

    .banner_bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .banner_h5_bg {
      display: none;
    }

    .banner-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 716px;
      margin: 32px auto 0;
      margin-top: 0;
      padding: 0px;
      background: #202124;
      // border-radius: 16px;

      .banner-left {
        width: 740px;
        height: auto;
        cursor: default;

        .title {
          position: relative;
          font-family: Poppins;
          font-size: 58px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          background: linear-gradient(90deg, #ffb5f8 0%, #61b8ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 32px;
        }

        .des {
          position: relative;
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }

      .banner-right {
        width: 745px;
        height: 643px;
        margin-top: 73px;
        z-index: 0;
        img {
          width: 745px;
          height: 643px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .banner-container {
    position: relative;
      box-sizing: border-box;
    overflow: hidden;
    .banner_bg {
      display: none;
    }
    .banner_h5_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .banner-box {
      position: relative;
      padding: .8rem .32rem 7.88rem;
      box-sizing: border-box;
      .banner-left {
        height: auto;
        position: relative;
        z-index: 1000;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        left: 0%;
        padding-bottom: 0.5rem;
        .title {
          font-family: Poppins;
          font-size: 0.56rem;
          font-style: normal;
          font-weight: 700;
          line-height: 0.68rem; /* 121.429% */
          background: linear-gradient(90deg, #ffb5f8 0%, #61b8ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0.32rem;
        }

        .des {
          color: #fff;
          font-family: Poppins;
          font-size: .28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          z-index: 100;
        }
      }

      .banner-right {
        height: 100%;
        width: 100%;

        img {
          position: absolute;
          bottom: 0;
          z-index: 8;
          width: 6.96rem;
          height: 6.43rem;
          object-fit: cover;
        }
      }
    }
  }
}
</style>