<template>
  <div class="price_container" id="price" ref="richTextContainer">
    <h1 class="price_title">{{ content.title }}</h1>
    <p class="price_des">{{ content.des }}</p>
    <div class="select-local" v-if="content.moreLocal && lang !== 'en'">
      <div class="local-title">Please select the exchange rate</div>
      <el-select v-model="country" placeholder="Please select language" style="width: 360px">
        <el-option v-for="(item, index) in contryList" :key="index" :label="item.key" :value="item.value" />
      </el-select>
    </div>

    <div class="price_wrapper" v-if="showLoading" :class="[`price_wrapper-${productList.length}`]">
      <img class="price_wrapper_background" :src="$getFile(content.backgroundImage)" alt="">

      <div class="note_wrap">
        <div class="note_title">{{content.noteTitle}}</div>
        <p v-for="(item, index) in content.noteList" :key="index">{{item.text}}</p>
      </div>

      <div class="price_setup" v-for="(item, idx) in priceSetup" v-show="productList[idx]" :key="idx">
        <div class="price_content">
          <div class="price_tag">{{ item.tag }}</div>

          <div v-if="productList[idx]" v-html="
          item.price.replace(/{money}/g, moneyShow(productList[idx]))
                .replace(/{currencyUnit}/g,productList[idx].currencyUnit || '$')
                .replace(/{useTime}/g, productList[idx].useTime)">
          </div>

        </div>
        <p class="price_tip" v-if="productList[idx]" v-html="
            item.tip
              .replace(/{money}/g, moneyShow(productList[idx]))
              .replace(/{price}/g, moneyShow(productList[idx], 'price'))
              .replace(/{useTime}/g, productList[idx].useTime)
              .replace(/{currencyUnit}/g, productList[idx].currencyUnit || '$')
          ">
        </p>
        <div class="price_desList" v-for="line in item.desList" :key="line">
          <img class="vector_icon" :src="$getFile('newimages/vector-icon.svg')" />
          <span v-html="
                line
                  .replace(
                    /{quota}/g,
                    (productList[idx] &&
                      productList[idx] &&
                      productList[idx].config &&
                      productList[idx].config.quota) ||
                      '1800'
                  )
                  .replace(
                    /{useTime}/g,
                    (productList[idx] && productList[idx].useTime) || 'month'
                  )
              "></span>
        </div>
        <button :style="`cursor: ${loading ? 'wait' : ''}`" v-show="productList.length" class="pay_button" @click="handlePay(idx)">
          {{ item.buttonTxt }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./ui/Pop-up.vue";

const currencyMap = {
  USD: "US$",
  PHP: "₱",
  ZAR: "R",
  GBP: "£",
  SGD: "S$",
  NZD: "NZD$",
  EUR: "€",
  INR: "₹",
  CAD: "C$",
  AUD: "AUD$",
  IDR: "Rp",
  MYR: "RM",
  KRW: "₩",
  HKD: "HK$",
  TWD: "NT$",
  BRL: "R$",
  THB: "฿",
};

const offerTypePrices = {
  0: "price",
  1: "tryoutPrice",
  2: "firstDiscountPrice",
};

const durationUnitType = {
  1: "hour",
  2: "day",
  3: "week",
  4: "month",
  5: "year",
  100: "Lifetime",
};

//  填充价格套餐
const useFillPriceInfo = (item) => {
  item.money = item[offerTypePrices[item.offerType]];

  let unit = "durationUnit";
  let duration = "duration";
  if (item.offerType === 1) {
    unit = "tryoutDurationUnit";
    duration = "tryoutDuration";
  }
  const durationUnit = item[unit];
  const durationDate = item[duration];

  item.useTime = durationUnitType[durationUnit];
  if (durationDate === 100) {
    item.useTime = "LifeTime";
  } else {
    item.useTime = durationDate + "-" + item.useTime;
  }

  item.currencyUnit = currencyMap[item.currency];
  if (item.config) {
    try {
      item.config = JSON.parse(item.config);
    } catch (error) {
      item.config = {};
    }
  } else {
    item.config = {};
  }
  return item;
};

import { getProductList, createOrder, payOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "Banner",
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => { },
    },
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
    defaultProductList: {
      type: Array,
      default: () => {
        return [
          {
            money: "49.8",
            price: "49.8",
            useTime: "1-month",
          },
          {
            money: "0.99",
            price: "49.8",
            useTime: "2-days",
          }
        ];
      },
    },
  },
  components: { Popup },
  computed: {
    lang() {
      let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
      return lang;
    },
    contryList() {
      return [
        { key: "USD", value: "1" },
        { key: "AUD", value: "0.67" },
        { key: "NZD", value: "0.61" },
        { key: "EUR", value: "1.09" },
      ];
    },
  },
  data() {
    return {
      country: "1",
      loading: false,
      showLoading: false,
      priceSetup: [],
      //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
      productList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let mainPart = $extractDomain(window.location.hostname);
      let channel =
        this.$route.query.channel || localStorage.getItem("channel");
      if (channel) {
        mainPart = `${mainPart}_${channel}`;
        localStorage.setItem("channel", channel);
      } else {
        let localLang = localStorage.getItem("lang") || "";
        let lang = this.$route.query.lang || localLang || "en";
        // if (lang !== "en") {
        //   mainPart = `${mainPart}_${lang}`;
        // }
      }
      getProductList({ code: mainPart, extend: {} })
        .then((result) => {
          const contentPriceList = JSON.parse(
            JSON.stringify(this.content.priceSetup)
          );
          let priceTime = '<span class="medium-bold">/{useTime}</span>';
          this.priceSetup = contentPriceList.map((res) => {
            if (
              !res.price.includes("{useTime}")
            ) {
              res.price = res.price + priceTime;
            }
            return res;
          });
          if (result.result && result.result.length) {
            this.productList = result.result.map(useFillPriceInfo);
          } else {
            this.productList = this.defaultProductList;
          }
          if (this.productList.length > this.priceSetup.length) {
            const priceItems = {
              oneOff: this.priceSetup.find((res) => res.buttonTxt === "Pay"),
              subscription: this.priceSetup.find(
                (res) => res.buttonTxt === "Trial Now"
              ),
            };
            this.priceSetup = this.productList.map((res) => {
              if (res.offerType !== 0) {
                return priceItems.subscription;
              }
              return priceItems.oneOff;
            });
          }
          this.$nextTick(() => {
            this.addClickEventToElement();
          });
          this.showLoading = true
        })
        .catch((e) => {
          this.productList = this.defaultProductList;
        });
    });
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) &&
          document.querySelector(this.$route.hash).scrollIntoView();
      });
    }
  },
  methods: {
    moneyShow(item, type = "money") {
      if (this.content.moreLocal && this.lang !== "en") {
        const local = this.contryList.find((res) => res.value === this.country);
        const money = Number(Number(item[type]) * Number(this.country)).toFixed(
          2
        );
        return `${currencyMap[local.key]} ${money}`;
      }
      return item[type];
    },
    addClickEventToElement() {
      const localLink =
        this.$refs.richTextContainer.querySelector("#accountLink");
      if (localLink) {
        localLink.addEventListener("click", this.goAccount);
      }
    },
    goAccount() {
      const has = localStorage.getItem("user_email");
      if (!has) {
        this.$store.commit("OPEN_LOGIN", true);
        sessionStorage.setItem("pushSta", 0);
      } else {
        this.$router.push({ path: "/sub/account" });
        this.$router.go(0);
      }
    },
    async handlePay(idx) {
      if (!this.productList[idx].productsPriceList) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const params = {
          productsId: this.productList[idx].id,
          productsPriceId: "0",
          successUrl: `${this.$origin(
            this.payUrl.successUrl || "sub/payresult"
          )}`, //  支付成功url
          cancelUrl: `${this.$origin(
            this.payUrl.cancelUrl || "sub/payresult"
          )}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketingChannel: "seo",
        };
        const userState = localStorage.getItem("user_email");
        if (userState) {
          params.email = userState;
        }
        const order = await createOrder(params);

        const result = await payOrder({
          orderId: order.obj.orderId,
          payExtend: {},
        });
        this.loading = false;
        window.location.href = result.obj.redirectUrl;
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    nopay() {
      alert("Please wait patiently for us to access the payment");
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .price_container {
    margin: 0 auto;
    width: 1572px;
    padding: 100px 0px;
    text-align: center;

    .price_title {
      margin-bottom: 24px;
      color: var(--text-icon-font-gy-190-primary, rgba(0, 0, 0, 0.9));
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
    }
    .price_des {
      width: 1149px;
      margin: 0 auto;
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 80px;
    }
    .select-local {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .local-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
      }
    }

    .price_wrapper {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      text-align: left;
      max-width: 1572px;
      padding: 77px 106px 64px;
      box-sizing: border-box;
      border-radius: 24px;
      .price_wrapper_background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-size: cover;
        z-index: -1;
      }
      .note_wrap {
        padding: 64px 28px;
        .note_title {
          color: #000;
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 24px;
        }
        p {
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
      }

      .price_setup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 432px;
        height: 383px;
        padding: 20px 30px;
        box-sizing: border-box;
        border-radius: 20px;
        background: #fff;
        .price_content {
          width: 100%;
          text-align: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #c7cef3;
          .price_tag {
            color: #6859fe;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 18px;
            line-height: normal;
          }
          ::v-deep .high-bold {
            color: #000;
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          ::v-deep .price-bold {
            font-family: Poppins;
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: linear-gradient(128deg, #f76cf9 3.14%, #5255fe 62.99%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          ::v-deep .medium-bold {
            color: #979797;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .price_tip {
          margin-top: 16px;
          font-size: 14px;
          color: var(--theme-primary-tiny);
          line-height: 18px;
        }

        .price_desList {
          width: 100%;
          margin-top: 24px;
          margin-bottom: 48px;
          display: flex;
          align-items: center;
          text-align: left;
          .vector_icon {
            margin-right: 12px;
          }
          span {
            color: #000;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
          }
        }

        .pay_button {
          display: flex;
          width: 306px;
          height: 59px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--theme-primary);
          border: none;
          color: #fff;
          font-family: Poppins;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .price_container {
    width: 100%;
    text-align: center;
    padding: 0.9rem 0.32rem;
    box-sizing: border-box;
    background: linear-gradient(177deg, #eeecff 0.3%, #e8f0ff 99.7%);
    .price_title {
      margin-bottom: 0.68rem;
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 0.48rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.48rem; /* 100% */
    }

    .price_des {
      position: relative;
      padding-bottom: 0.48rem;
      color: #000;
      font-family: Poppins;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      border-bottom: 1px solid #5356FF;
      &::after{
        position: absolute;
        bottom: -5px;
        left: 0;
        content:'';
        width: 8px;
        height: 8px;
        background: #5356FF;
        border-radius: 50%;
        box-shadow: 0 0 0px 5px #eeecff;
      }
    }

    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: var(--theme-primary-black);
      line-height: 28px;
    }

    &::v-deep .medium-bold {
      margin-top: 10px;
      font-size: 14px;
      color: var(--theme-primary-tiny);
      line-height: 18px;
    }

    .price_wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      text-align: left;

      .price_wrapper_background {
        display: none;
      }

      .note_wrap {
        padding: 0.48rem 0;
        .note_title {
          color: #000;
          font-family: Poppins;
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 600;
          line-height: 0.48rem; /* 150% */
          margin-bottom: 0.24rem;
        }
        p {
          color: #000;
          font-family: Poppins;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0.08rem;
        }
      }

      .price_setup {
        min-width: 100%;
        padding: 0.4rem .4rem .14rem;
        box-sizing: border-box;
        border-radius: 0.4rem;
        background: #fff;
        margin-bottom: 0.42rem;
        &:last-child{
          margin-bottom: 0;
        }

        .price_content {
          width: 100%;
          text-align: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #c7cef3;
          .price_tag {
            color: #6859fe;
            text-align: center;
            font-family: Poppins;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0.16rem;
          }

          ::v-deep .high-bold {
            color: #000;
            font-family: Poppins;
            font-size: 0.32rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          ::v-deep .price-bold {
            font-family: Poppins;
            font-size: 0.68rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: linear-gradient(128deg, #f76cf9 3.14%, #5255fe 62.99%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          ::v-deep .medium-bold {
            color: #979797;
            font-family: Poppins;
            font-size: 0.22rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.3rem;
          }
        }

        .price_tip {
          margin-top: 0.18rem;
          color: #979797;
          font-family: Poppins;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .price_desList {
          margin-top: 0.24rem;
          margin-bottom: 0.48rem;
          display: flex;
          align-items: center;
          .vector_icon {
            margin-right: 0.22rem;
          }
          span {
            color: #000;
            font-family: Poppins;
            font-size: 0.32rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .pay_button {
          display: flex;
          width: 6.12rem;
          height: 1.18rem;
          padding: 0.2rem;
          justify-content: center;
          align-items: center;
          gap: 0.2rem;
          color: #fff;
          font-family: Poppins;
          font-size: .4rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          flex-shrink: 0;
          border-radius: 0.16rem;
          border: none;
          background: var(--theme-primary);
        }
      }
    }
  }
}
</style>
