<template>
  <div class="introduce_wrap">
    <h2 class="title">{{ content.title }}</h2>
    <div class="introduce_list">
      <div v-for="(item, idx) in content.list" :key="idx">
        <div class="introduce_item">
          <img :src="$getFile(item.img)" />
          <div class="item_left">
            <h3 class="mini_title">{{ item.title }}</h3>
            <p class="mini_des">{{ item.des }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="introduce_list_h5">
      <div class="swiper-box">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in content.list" :key="index">
            <div class="introduce_item">
              <img :src="$getFile(item.img)" />
              <div class="item_left">
                <h3 class="mini_title">{{ item.title }}</h3>
                <p class="mini_des">{{ item.des }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div v-if="content.list.length > 1" class="swiper-pagination"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Introduce',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .introduce_wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    text-align: center;

    .title {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
    }

    .introduce_list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
      margin-top: 60px;
      text-align: left;

      .introduce_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 580px;
        height: 227px;
        padding: 30px;
        box-sizing: border-box;
        gap: 24px;
        border-radius: 12px;
        border: 1px solid var(--theme-primary-suffix);
        background: #eeecff;
        .item_left {
          .mini_title {
            margin-bottom: 16px;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 140% */
            background: linear-gradient(123deg, #5255fe 34.24%, #b254ff 79.09%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .mini_des {
            color: #333;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }

        img {
          width: 140px;
          height: 140px;
        }
      }
    }
    .introduce_list_h5 {
      display: none;
    }
  }
}

@media (max-width: 980px) {
  .introduce_wrap {
    padding: 1rem 0.55rem;

    .title {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-family: Poppins;
      font-size: 0.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 0.48rem; /* 120% */
      margin-bottom: 0.68rem;
      text-align: center;
    }

    .des {
      font-size: 0.28rem;
      font-weight: 400;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-black);
      line-height: 0.43rem;
    }

    .introduce_list {
      display: none;
    }

    .introduce_list_h5 {
      overflow: hidden;

      .swiper-box {
        transform: scale(1);
        .swiper-container {
          width: 100%;
          margin: 0;
          box-shadow: none;
        }
        .introduce_item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 0.24rem;
          border: 0.02rem solid var(--theme-primary-suffix);
          background: #eeecff;
          .item_left {
            .mini_title {
              text-align: center;
              font-family: Poppins;
              font-size: 0.32rem;
              font-style: normal;
              font-weight: 600;
              line-height: 0.56rem; /* 175% */
              background: linear-gradient(
                123deg,
                #5255fe 34.24%,
                #b254ff 79.09%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-top: 0.48rem;
              margin-bottom: 0.32rem;
            }
            .mini_des {
              color: #333;
              text-align: center;
              font-family: Poppins;
              font-size: 0.28rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 0.51rem;
              padding: 0 0.2rem;
            }
          }

          img {
            width: 2.8rem;
            height: 2.8rem;
            margin-top: 0.51rem;
          }
        }
      }
      .swiper-pagination {
        padding: 0;
        margin-top: 0.32rem;
        ::v-deep .swiper-pagination-bullet {
          width: 0.2rem;
          height: 0.2rem;
          background-color: #eeecff;
        }

        ::v-deep .swiper-pagination-bullet-active {
          width: 1.92rem;
          height: 0.2rem;
          border-radius: 8px;
          opacity: 0.8;
          background-color: #7562f2;
        }
      }
    }
  }
}
</style>