<template>
  <!-- 首页轮播图组件 -->
  <div class="reading-mode-container">
    <h2 v-html="content.title"></h2>
    <div>
      <div class="reading-content">
        <div class="reading-left">
          <div v-for="(item, index) in content.list" v-show="activeIdx == index" :key="index">
            <img class="reading-img" :src="$getFile(item.leftImg)">
          </div>
        </div>
        <div class="reading-right">
          <div class="reading-right-content">
            <div :class="['reading-content-item', activeIdx == index ? '' : 'active']" v-for="(item, index) in content.list" :key="index" @mousemove="hoverHandle($event, index)">
              <div class="container">
                <img class="reading-img" :src="$getFile(item.icon)" v-if="item.icon" />
                <div :class="['reading-number', activeIdx == index ? 'reading-number-active' : '']" v-else>{{ index + 1 }}</div>
                <div :class="['reading-title', activeIdx == index ? 'reading-title-active' : '']">{{ item.title }}</div>
              </div>
              <div v-show="activeIdx == index" class="reading-desc">{{ item.desc }}</div>
            </div>
          </div>
          <!-- H5 -->
          <div class="reading-swiper-box">
            <swiper ref="swiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in content.list" :key="index">
                <div class="reading-content-item">
                  <div class="row-center">
                    <div class="reading-img">
                      <img :src="$getFile(item.leftImg)" alt="">
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <div class="reading-swiper-pagation">
              <div v-for="(item, index) in content.list" :key="index" :class="['reading-swiper-pagation-dot', swiperIdx == index ? 'active' : '']" @click="swiperSwitch(index)">
                <div class="container">
                  <img class="reading-img" :src="$getFile(item.icon)" v-if="item.icon" />
                  <div :class="['reading-number', swiperIdx == index ? 'reading-number-active' : '']" v-else>{{ index + 1 }}</div>
                  <div :class="['reading-title', swiperIdx == index ? '' : 'reading-title-active']">{{ item.title }}</div>
                </div>
                <div v-show="swiperIdx == index" class="reading-desc">{{ item.desc }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils'
export default {
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    let that = this
    return {
      activeIdx: 0,
      swiperIdx: 0,
      swiperOptions: {
        initialSlide: 0,
        autoplay: true,
        allowTouchMove: false,
        disableOnInteraction: true,
        loop: true,
        on: {
          transitionStart() {
            that.swiperIdx = this.realIndex
          }
        }
      },
      stopHandle: true
    }
  },
  mounted() {
    this.index = 0
    if (!utils.isMobile()) {
      this.timer = setInterval(() => {
        if (!this.stopHandle) {
          return
        }
        if (this.index > this.content.list.length - 1) {
          this.index = 0
        }
        this.hoverHandle(null, this.index)
        this.index++
      }, 3500)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    hoverHandle(e, index) {
      this.activeIdx = index
      this.index = index
      if (e) {
        let event = e.type
        switch (event) {
          case 'mouseenter':
            this.stopHandle = false
            break
          case 'mouseleave':
            this.stopHandle = true
            break
        }
      }
    },
    swiperSwitch(idx) {
      this.swiperIdx = idx
      this.$refs.swiper.swiper.slideTo(idx + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 981px) {
  .reading-mode-container {
    position: relative;
    padding: 80px 0;
    width: 100%;
    margin: 0 auto;
    background: #e6e6fe;

    h2 {
      width: 1200px;
      font-size: 48px;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: 56px;
      text-align: center;
      margin: 0 auto;

      ::v-deep .Introduce-ttt {
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .reading-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 80px;

      .reading-left {
        margin-right: 68px;
        position: relative;

        .reading-img {
          width: 500px;
        }

        .reading-img1 {
          position: absolute;
          width: 483px;
          height: 239px;
          left: 20px;
          top: 20px;
          animation: move 2s ease-in infinite;
        }

        .reading-img2 {
          position: absolute;
          width: 500px;
          height: 303px;
          left: 20px;
          top: 30px;
          animation: move 2s ease-in infinite;
        }

        .reading-img3 {
          position: absolute;
          width: 449px;
          height: 277px;
          left: 40px;
          top: 110px;
          animation: move 2s ease-in infinite;
        }

        .reading-img4 {
          position: absolute;
          width: 479px;
          left: 22px;
          top: 26px;
          animation: move 2s ease-in infinite;
        }
      }

      .reading-right {
        .reading-right-content {
          .reading-content-item {
            width: 90%;
            max-width: 640px;
            padding: 20px;
            border-radius: 8px;
            // background: var(--theme-primary);
            background: linear-gradient(108deg, #d0d5fe 1.75%, #a496ff 93.17%);
            transition: height 0.2s ease;
            cursor: pointer;

            &:not(:last-of-type) {
              margin-bottom: 12px;
            }

            &.active {
              // background: var(--theme-primary-suffix);
              background: rgba(255, 255, 255, 0.8);
            }

            .container {
              display: flex;
              align-items: center;
            }

            .reading-img {
              width: 36px;
              height: 36px;
              margin-right: 10px;
            }

            .reading-number {
              width: 34px;
              height: 34px;
              background: linear-gradient(
                108deg,
                #a496ff 1.75%,
                #a8c4ff 93.17%
              );
              border-radius: 50%;
              color: #fff;
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 34px; /* 140% */
              text-align: center;
              margin-right: 10px;
            }

            .reading-number-active {
              background: #a496ff;
            }

            .reading-title {
              color: #8862fa;
              font-family: Poppins;
              font-size: 28px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px;
            }
            .reading-title-active {
              color: #fff;
            }

            .reading-desc {
              margin-left: 46px;
              margin-top: 8px;
              color: #fff;
              font-family: Poppins-Regular, Poppins;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .reading-swiper-box {
          display: none;
        }

        .start-btn {
          color: white;
          font-size: 18px;
          line-height: 30px;
          font-family: Poppins-Medium, Poppins;
          background-color: var(--theme-primary);
          width: 200px;
          height: 56px;
          margin-top: 24px;
          cursor: pointer;

          &:hover {
            background-color: var(--theme-btn-hover);
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .reading-mode-container {
    position: relative;
    padding: 1.2rem 0.32rem 0.87rem;
    box-sizing: border-box;

    h2 {
      font-size: 0.48rem;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: 0.72rem;
      text-align: center;
      margin: 0 auto;
    }

    .reading-content {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 0.48rem;

      .reading-left {
        display: none;
      }

      .reading-right {
        .reading-right-content {
          display: none;
        }

        .reading-swiper-box {
          position: relative;
          width: 100%;

          // .swiper-container {
          //   width: 100%;
          // }

          .reading-content-item {
            width: 100%;
            overflow: hidden;

            .reading-img {
              width: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .reading-swiper-pagation {
            .reading-swiper-pagation-dot {
              padding: 0 0.4rem;
              border-radius: 0.1rem;
              transition: width 0.2s ease;
              margin-bottom: 0.2rem;
              box-sizing: border-box;
              background: rgba(233, 231, 255, 0.8);

              .container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 6.46rem;
                border-radius: 0.16rem;
                padding: 0.25rem 0;

                .reading-img {
                  width: 0.4rem;
                  margin-right: 0.12rem;
                }

                .reading-number {
                  width: 0.68rem;
                  height: 0.68rem;
                  background: linear-gradient(
                    108deg,
                    #a496ff 1.75%,
                    #a8c4ff 93.17%
                  );
                  border-radius: 50%;
                  color: #fff;
                  font-family: Roboto;
                  font-size: 0.4rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 0.68rem; /* 140% */
                  text-align: center;
                  margin-right: 0.2rem;
                }

                .reading-number-active {
                  background: #a496ff;
                }

                .reading-title {
                  font-size: 0.32rem;
                  font-family: Poppins-Medium, Poppins;
                  color: #fff;
                  line-height: 0.45rem;
                  margin-left: 0.05rem;
                }
                .reading-title-active {
                  color: #8862fa;
                }
              }

              .reading-desc {
                font-size: 0.28rem;
                font-family: Poppins-Regular, Poppins;
                color: #fff;
                line-height: 0.4rem;
                padding-bottom: 0.2rem;
              }
            }
            .active {
              background: linear-gradient(
                108deg,
                #d0d5fe 1.75%,
                #a496ff 93.17%
              );
            }
          }
        }

        .start-btn {
          display: none;
          color: white;
          font-size: 18px;
          line-height: 30px;
          font-family: Poppins-Medium, Poppins;
          background-color: var(--theme-primary);
          width: 200px;
          height: 56px;
          margin-top: 24px;
          cursor: pointer;

          &:hover {
            background-color: var(--theme-btn-hover);
          }
        }
      }
    }
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>