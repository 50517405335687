<template>
  <div class="footer_container">
    <div class="footer_box">
      <div class="top-container">
        <div class="main_container">

          <div class="container">
            <div class="footer_top">
              <img class="logo" src="../assets/images/newimages/logo2.svg" />
              <div class="intro" v-html="content.des"></div>
              <div class="intro" style="margin-top: 40px" v-if="content.information" v-html="content.information"></div>
            </div>
            <p class="introH5" v-html="content.des"></p>
          </div>

          <div class="footer_list">
            <div v-for="(item, idx) in fastList" :key="idx" class="footer-item">
              <div class="item-title">{{ item.title }}</div>
              <p v-for="(target, tIdx) in item.list" :key="tIdx">
                <template v-if="target.type !== undefined">
                  <a :href="target.name">{{ target.title }}</a>
                </template>
                <template v-else>
                  <a :rel="target.rel || ''" :href="target.link">
                    {{target.title}}
                  </a>
                </template>
              </p>
            </div>
          </div>

          <div class="footer_list_h5">
            <div v-for="(item, idx) in fastList" :key="idx" class="footer-item">
              <div class="item-title" @click="handleFast(idx)">
                {{ item.title }}
                <img class="foot_arrow" :style="{rotate:openNmber == idx?'0deg':'180deg'}" :src="$getFile('newimages/foot_arrow.svg')" />
              </div>

              <template v-if="openNmber == idx">
                <p v-for="(target, tIdx) in item.list" :key="tIdx">
                  <template v-if="target.type !== undefined">
                    <a :href="target.name">{{ target.title }}</a>
                  </template>
                  <template v-else>
                    <a :rel="target.rel || ''" :href="target.link">
                      {{target.title}}
                    </a>
                  </template>
                </p>
              </template>
            </div>
          </div>

        </div>
      </div>

      <div class="bottom-container">
        <div class="bottom-box row-between">
          <div class="business_info">
            <div class="text" v-html="content.bottomMobile"></div>
            <img :src="$getFile(content.bottomImg || 'pay-bg2.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    afterBrand: {
      type: String,
      default: '',
    },
    beforeBrand: {
      type: String,
      default: '',
    },
    showProduct: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
    siteType: {
      type: Number,
      default: 1,
    },
    navBarListFooter: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      direction: "down",
      openNmber: 0
    };
  },
  computed: {
    fastList() {
      let list = this.content.list;
      if (this.showProduct) {
        let newList = this.navBarListFooter.map(res => {
          res.isOpen = false;
          res.list = res.list.map(item => {
            item.title = `${this.beforeBrand} ${item.title}`;
            return item;
          })
          return res;
        })
        list = newList.concat(list)
      }
      return list || [];
    },
  },
  mounted() { },
  methods: {
    handleMenu(flag) {
      this.direction = flag ? "up" : "down";
    },

    handleFast(idx) {
      if (this.openNmber == idx) {
        this.openNmber = 666
        return;
      }
      this.openNmber = idx
    }
  },
};
</script>

<style scoped lang="scss">
.logo {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--theme-primary-white);
}

@media (min-width: 980px) {
  .footer_container {
    padding: 90px 0 0;
    background: var(--theme-primary-footer-bg);

    .footer_box {
      .top-container {
        .main_container {
          display: flex;
          justify-content: center;
          padding: 0 0 90px 0;
          gap: 104px;
          border-bottom: #ffffff33 2px solid;
          .container {
            .footer_top {
              width: 376px;

              .logo {
                width: 179px;
              }

              .intro {
                width: 280px;
                margin-top: 17px;
                margin-right: 60px;
                font-size: 14px;
                font-weight: 400;
                font-family: Poppins-Regular, Poppins;
                color: var(--theme-primary-white);
                line-height: 22px;
              }
            }

            .introH5 {
              display: none;
            }
          }

          .footer_list {
            display: flex;
            gap: 44px;

            .footer-item {
              width: 160px;

              .item-title {
                margin-bottom: 24px;
                color: #fff;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
              }

              p {
                color: #fff;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                cursor: pointer;
                margin-bottom: 14px;
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  color: var(--theme-primary-white);
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
          .footer_list_h5 {
            display: none;
          }
        }
      }

      .bottom-container {
        width: auto;
        padding: 20px 0;
        background: #0d0d0e;
        color: var(--theme-primary-white);

        .bottom-box {
          width: 1180px;
          margin: 0 auto;
          text-align: center;

          .business_info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .text {
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
            img {
              height: 32px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .footer_container {
    .footer_box {
      width: auto;
      background: var(--theme-primary-footer-bg);
      color: var(--theme-primary-white);

      .main_container {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
        margin: 0 auto;
        padding-top: 0.8rem;
        box-sizing: border-box;
        border-bottom: #ffffff33 1px solid;

        .container {
          margin-top: 0.8rem;
          .footer_top {
            width: 3.27rem;
            height: 0.92rem;
            margin: 0 auto;
            .logo {
              width: 3.27rem;
              height: 0.92rem;
            }

            .intro {
              display: none;
            }
          }

          .introH5 {
            font-size: 0.24rem;
            font-weight: 400;
            font-family: Poppins-Regular, Poppins;
            color: var(--theme-primary-white);
            line-height: 0.44rem;
            text-align: center;
            margin: 0 0.32rem;
            margin-top: 0.4rem;
            margin-bottom: 0.3rem;
          }
        }

        .footer_list_h5 {
          width: 100%;
          .footer-item {
            padding: 0.32rem 0 0;
            margin: 0 0.32rem;
            border-bottom: 1px solid #e3e7e3;
            .item-title {
              display: flex;
              justify-content: space-between;
              padding-bottom: 0.32rem;
              font-size: 0.32rem;
              font-weight: bold;
              font-family: Poppins;
              color: var(--theme-primary-white);
              .foot_arrow {
                width: 0.32rem;
                height: 0.32rem;
                transition: all 0.3s ease;
              }
            }

            p {
              font-size: 0.28rem;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              line-height: 0.46rem;
              cursor: pointer;
              word-wrap: break-word;
              margin-bottom: 0.32rem;

              a {
                color: var(--theme-primary-white);
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .footer_list {
          display: none;
        }
      }

      .bottom-container {
        margin: 0 0.32rem;
        padding: 0.24rem 0 0.3rem;

        font-family: Poppins-Regular, Poppins;
        color: var(--theme-primary-white);
        align-items: center;
        text-align: center;

        .business_info {
          color: #fff;
          font-family: Poppins;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.35rem;
          .text {
            font-size: 0.24rem;
          }
          img {
            height: 32px;
          }
        }

        img {
          width: 100%;
          margin-top: 0.3rem;
        }
      }
    }
  }
}
</style>
