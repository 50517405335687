<template>
  <!-- 转换页解释组件 -->
  <div class="explain_container">
    <h2 class="title">{{ content.title }}</h2>
    <p class="des">{{ content.des }}</p>
    <div class="explain_list">
      <template v-for="(item, idx) in content.list">
        <div :key="idx" class="explain_item">
          <div class="item-left">
            <h3 class="mini-title" v-html="item.title"></h3>
            <p class="mini-des">{{ item.des }}</p>
          </div>
          <img :src="$getFile(item.img)" v-if="item.img" alt="">
          <ImageComparisonNew :dataList="item" :i="idx" :styleWidth="styleW" :styleHeight="styleH" v-else />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import utils from "@/common/utils";
export default {
  name: 'Explain',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    styleW() {
      return utils.isMobile() ? '6.86rem' : '560px'
    },
    styleH() {
      return utils.isMobile() ? '4.9rem' : '400px'
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .explain_container {
    width: 1148px;
    margin: 0 auto;
    padding: 80px 0;
    text-align: center;

    .title {
      color: var(--theme-primary-black);
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
      margin-bottom: 24px;
    }

    .des {
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    .explain_list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 68px;
      margin-top: 80px;
      text-align: left;

      .explain_item {
        display: flex;
        justify-content: space-between;

        // 双数反转
        &:nth-child(2n + 1) {
          flex-direction: row-reverse;
        }

        .item-left {
          width: 520px;

          .mini-title {
            margin-top: 42px;
            font-size: 24px;
            font-weight: bold;
            font-family: Poppins-Bold, Poppins;
            color: var(--theme-primary-black);
            line-height: 32px;
            margin-bottom: 32px;
            ::v-deep .mini-ttt {
              display: inline-block;
              width: 420px;
              position: relative;
              font-family: Poppins;
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px; /* 128.571% */
              background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding-bottom: 12px;
              &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                width: 120px;
                height: 4px;
                background: linear-gradient(
                  90deg,
                  #6596fe 0%,
                  rgba(101, 150, 254, 0) 100%
                );
              }
            }
          }
          .mini-des {
            color: #333;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }

        img {
          width: 560px;
          border-radius: 16px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .explain_container {
    width: 6.86rem;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;

    .title {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-family: Poppins;
      font-size: 0.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 0.48rem; /* 120% */
      margin-bottom: 0.24rem;
    }

    .des {
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .explain_list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 0.8rem;
      margin-top: 0.8rem;
      text-align: left;

      .explain_item {
        display: flex;
        flex-direction: column-reverse;

        .item-left {
          margin-top: 0.68rem;
          .mini-title {
            margin-bottom: 0.32rem;
            color: #333;
            font-family: Poppins;
            font-size: 0.4rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.48rem; /* 120% */
            ::v-deep .mini-ttt {
              display: inline-block;
              position: relative;
              font-family: Poppins;
              font-size: .4rem;
              font-style: normal;
              font-weight: 500;
              line-height: .48rem; /* 128.571% */
              background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding-bottom: .28rem;
              &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                width: 2.4rem;
                height: .04rem;
                background: linear-gradient(
                  90deg,
                  #6596fe 0%,
                  rgba(101, 150, 254, 0) 100%
                );
              }
            }
          }
          .mini-des {
            color: #333;
            font-family: Poppins;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        img {
          display: block;
          width: 6.68rem;
          // height: 4.57rem;
          margin: 0 auto 0rem auto;
          border-radius: .16rem;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 980px) {
  .ai-collection {
    position: relative;

    .example-pic {
      position: relative;
      height: 312px;
      background: #d8d8d8;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 10;

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        z-index: 60;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }

        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          background: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .ai-collection {
    position: relative;

    .example-pic {
      flex: 0 0 7.5rem;
      position: relative;
      height: 7.8rem;
      overflow: hidden;
      border-radius: 0.16rem;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        overflow: hidden;
        z-index: 10;

        .ori-pic {
          width: 7.5rem;
          height: 7.8rem;
        }

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 7.8rem;
        z-index: 12;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }

        .move-help {
          position: absolute;
          top: 55%;
          right: 50%;
          width: 0.62rem;
          height: 0.88rem;
          animation: mymove 3s linear 0s infinite;
        }

        @keyframes mymove {
          0% {
            opacity: 1;
            right: 50%;
          }

          50% {
            opacity: 0.5;
            right: -75%;
          }

          100% {
            opacity: 1;
            right: 50%;
          }
        }

        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 7.8rem;
          background: #ffffff;
        }
      }
    }
  }
}
</style>