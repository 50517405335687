<template>
  <!-- 底部导航栏组件 -->
  <div class="footer-container">
    <div class="footer-box">
      <div class="top-container">
        <div class="main-container">
          <div class="footer-top">
            <div class="logo">{{ content.title }}</div>
            <div class="intro" v-html="content.des"></div>
            <div class="intro" style="margin-top: 40px" v-if="content.information" v-html="content.information"></div>
          </div>
          <p class="introH5" v-html="content.des"></p>
          <div class="footer-list">
            <div v-for="(item, idx) in fastList" :key="idx" class="footer-item">
              <div class="item-title">{{ item.title }}</div>
              <p v-for="(target, tIdx) in item.list" :key="tIdx">
                <template v-if="target.type !== undefined">
                  <a :href="target.name">{{ target.title }}</a>
                </template>
                <template v-else>
                  <a :rel="target.rel || ''" :href="target.link">{{
                    target.title
                  }}</a>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <div class="bottom-box row-between">
          <div class="business-info">
            <div v-html="content.bottomMobile"></div>
            <img :src="$getFile(content.bottomImg || 'pay-bg2.png')" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom-mobile">
        <div v-html="content.bottomMobile"></div>
        <div v-if="content.information" v-html="content.information"></div>
        <img :src="$getFile(content.bottomImg || 'pay-bg2.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    afterBrand: {
      type: String,
      default: '',
    },
    beforeBrand: {
      type: String,
      default: '',
    },
    showProduct: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
    siteType: {
      type: Number,
      default: 1,
    },
    navBarListFooter: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      direction: "down",
    };
  },
  computed: {
    fastList() {
      let list = this.content.list;
      if (this.showProduct) {
        let newList = this.navBarListFooter.map(res => {
          res.list = res.list.map(item => {
            item.title = `${this.beforeBrand} ${item.title}`
            return item;
          })
          return res;
        })
        list = newList.concat(list)
      }
      return list || [];
    },
  },
  mounted() { },
  methods: {
    handleMenu(flag) {
      this.direction = flag ? "up" : "down";
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--theme-primary-white);
}

@media (min-width: 980px) {
  .footer-container {
    .footer-box {
      .top-container {
        width: auto;
        padding-top: 60px;
        background: var(--theme-primary-footer-bg);

        .main-container {
          display: flex;
          justify-content: space-between;
          width: 90%;
          max-width: 1180px;
          margin: 0 auto;
          padding-bottom: 60px;
          box-sizing: border-box;
          border-bottom: #ffffff33 2px solid;

          .footer-top {
            width: 300px;

            img {
              width: 179px;
            }

            .intro {
              width: 280px;
              margin-top: 17px;
              margin-right: 60px;
              font-size: 14px;
              font-weight: 400;
              font-family: Poppins-Regular, Poppins;
              color: var(--theme-primary-white);
              line-height: 22px;
            }
          }

          .footer-list {
            display: flex;
            gap: 44px;

            .footer-item {
              width: 160px;

              .item-title {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 600;
                font-family: Poppins-SemiBold, Poppins;
                color: var(--theme-primary-white);
                line-height: 24px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                font-family: Poppins-Regular, Poppins;
                word-wrap: break-word;
                line-height: 22px;
                cursor: pointer;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }

                a {
                  color: var(--theme-primary-white);
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        .introH5 {
          display: none;
        }
      }

      .bottom-container {
        width: auto;
        padding: 20px 0;
        background: var(--theme-primary-footer-bg);
        color: var(--theme-primary-white);

        .bottom-box {
          width: 1180px;
          margin: 0 auto;
          text-align: center;

          .business-info {
            position: relative;
            font-size: 12px;
            line-height: 19px;
            font-family: Poppins-Regular, Poppins;
            text-align: center;

            img {
              position: absolute;
              right: 0;
              top: -4px;
              width: 300px;
            }
          }
        }
      }

      .bottom-mobile {
        display: none;
      }
    }
  }
}

@media (max-width: 981px) {
  .logo {
    text-align: center;
  }

  .footer-container {
    .footer-box {
      width: auto;
      background: var(--theme-primary-footer-bg);
      color: var(--theme-primary-white);

      .main-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding-top: 1rem;
        box-sizing: border-box;
        border-bottom: #ffffff33 1px solid;

        .footer-top {
          width: 3.57rem;
          height: 0.46rem;

          img {
            display: none;
          }

          .intro {
            display: none;
          }
        }

        .introH5 {
          height: 1.76rem;
          margin-top: 0.4rem;
          font-size: 0.28rem;
          font-weight: 400;
          font-family: Poppins-Regular, Poppins;
          color: var(--theme-primary-white);
          line-height: 0.44rem;
          text-align: center;
        }

        .footer-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.78rem 0.8rem;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0.8rem 0;
          box-sizing: border-box;

          .footer-item {
            width: 2.88rem;

            .item-title {
              margin-bottom: 0.3rem;
              font-size: 0.32rem;
              font-family: Poppins;
              color: var(--theme-primary-white);
              line-height: 0.53rem;
            }

            p {
              font-size: 0.3rem;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              line-height: 0.46rem;
              cursor: pointer;
              word-wrap: break-word;

              &:not(:last-child) {
                margin-bottom: 0.32rem;
              }

              a {
                color: var(--theme-primary-white);
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .bottom-container {
        display: none;
      }

      .bottom-mobile {
        margin: 0 0.32rem;
        padding: 0.41rem 0 0.3rem;
        font-size: 0.24rem;
        font-family: Poppins-Regular, Poppins;
        color: var(--theme-primary-white);
        align-items: center;
        line-height: 0.35rem;
        text-align: center;

        img {
          width: 3rem;
        }
      }
    }
  }
}
</style>
