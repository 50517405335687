<template>
  <!-- 顶部导航栏组件 -->
  <div class="nav_comtainer">
    <div :class="['nav_wrap', scrollFill ? 'shadow' : '']" ref="navBar" :style="{ background: scrollFill ? '#FFFFFF' : topEleBg }">
      <div class="container">
        <div class="menu_wrap row-between">
          <a class="nav_left" href="/">
            <img src="../assets/images/newimages/logo.svg" />
          </a>

          <div class="flex">
            <div class="nav_right row">
              <div class="tools_menus row">
                <div class="tools_box">
                  <div class="downlod_title row">
                    {{content.productsName||'Products'}}
                    <img class="trangle_icon" :src="$getFile('newimages/down_arrow.svg')" />
                    <div class="convert_container">
                      <div class="convert_content row">
                        <div class="convert_content_item" v-for="(items, index) in navBarList" :key="index">

                          <div class="convert_title">{{ items.title }}</div>

                          <div class="feature_list">
                            <div class="feature_list_item row" v-for="nod in items.list" :key="nod.type" @click="goTo(nod)">
                              <div class="feature_text">
                                {{beforeBrand}} {{ nod.title }}
                                <img src="../assets/images/newimages/hot_icon.svg" v-if="nod.tag" />
                              </div>
                              <div class="feature_des" v-if="nod.des">
                                {{ nod.des }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="tools_menus row">
                <div class="tools_box">
                  <div class="row">
                    <kefu :hideKefu="content.hideKefu || false" />
                  </div>
                </div>
                <div class="tools_box">
                  <div class="row">
                    <a :href="content.fastLink" v-if="content.fastClick">
                      {{content.fastClick}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoginWrapNew />

      </div>
    </div>

    <div class="h5_nav_bar">
      <HubMobileNavBar :scrollFill="scrollFill" :siteType="siteType" :navBarList="navBarList" :content="content" :afterBrand="afterBrand" :beforeBrand="beforeBrand" />
    </div>
  </div>
</template>

<script>
import utils from "@/common/utils";
import DropDown from "./ui/DropDown.vue";
import kefu from "./ui/kefu.vue";

export default {
  name: "HubNavBar",
  components: { DropDown, kefu },
  data() {
    return {
      scrollFill: false,
      color: "",
      // 手机端代码支持
      showMobileHeader: false,
      // end
      showTools: false,
      panelShow: false,
    };
  },
  props: {
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    siteType: {
      type: Number,
      default: 1,
    },
    afterBrand: {
      type: String,
      default: '',
    },
    beforeBrand: {
      type: String,
      default: '',
    },
    navBarList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    topEleBg() {
      return "#fff";
    },
  },
  watch: {
    topEleBg: {
      handler(val) {
        this.setFontColor(val);
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    // 支持手机端代码
    this.headerCtrl();
    window.addEventListener("resize", this.headerCtrl.bind(this));
  },
  mounted() {
    this.setFontColor(this.topEleBg);
    this.$store.commit("SAVE_LOGIN_INFO", this.content.loginInfo);
  },
  methods: {
    goTo(item) {
      this.showTools = false;
      if (Number(item.type) === Number(this.siteType)) {
        window.location.href = window.location.origin;
        return;
      }
      window.location.href = item.url;
    },
    handleLogin() {
      this.$store.commit("openDrawer", false);
      this.$store.commit("changeDialog", {
        name: "FormLayout",
        active: "Login",
      });
    },
    headerCtrl(e) {
      if (document.body.getBoundingClientRect().width <= 980) {
        this.showMobileHeader = true;
      } else {
        this.showMobileHeader = false;
      }
    },
    // val：navBar背景色，通过计算得出navbar文字深浅颜色
    setFontColor(val) {
      this.$nextTick(() => {
        let bg = utils.set16ToRgb(val);
        let r = 0;
        if (val.match(/#\w{6,8}/)) {
          r = parseInt(bg.substr(4, 3));
        }
        this.color = 255 - r > 100 ? "#FFFFFF" : "#011E15";
      });
    },
    handleScroll() {
      if (window.scrollY === 0) {
        this.scrollFill = false;
      } else {
        this.scrollFill = true;
      }
    },

    togglePanel() {
      this.panelShow = !this.panelShow;
      if (this.panelShow) {
        document.addEventListener("click", this.closeList);
      } else {
        document.removeEventListener("click", this.closeList);
      }
    },

    closeList() {
      this.panelShow = false;
    }
  },
};
</script>

<style lang="scss" scoped>
$commonColor: "#8567F9"; //统一颜色
@mixin flex-around {
  display: flex;
  justify-content: space-around;
}

.shadow {
  box-shadow: 0 1px 5px 0 rgba(184, 184, 184, 0.5);
}

.flex {
  display: flex;
  align-items: center;
  gap: 100px;
}
@media (min-width: 981px) {
  .nav_comtainer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #efefef;

    .nav_wrap {
      position: relative;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1260px;
        height: 80px;
        margin: 0 auto;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #020b22;
        z-index: 2;

        .menu_wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .nav_left {
            color: #000;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            white-space: nowrap;
            cursor: pointer;
            img {
              width: 123px;
              height: 34px;
              margin-right: 72px;
              cursor: pointer;
            }
          }
          .nav_right {
            width: auto;
            height: 100%;
            display: flex;

            .tools_menus {
              display: flex;
              align-items: center;
              height: 100%;
              .tools_box {
                display: flex;
                align-items: center;
                color: #353535;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                padding: 0 16px;
                .downlod_title {
                  &::after {
                    position: absolute;
                    top: 50px;
                    left: 0;
                    display: none;
                    content: "";
                    width: 100%;
                    height: 50px;
                  }
                  &:hover::after {
                    display: block;
                  }
                  &:hover .convert_container {
                    display: block;
                  }
                  &:hover .trangle_icon {
                    rotate: 180deg;
                  }
                  .trangle_icon {
                    width: 12px;
                    rotate: 0deg;
                    height: 8px;
                    margin-left: 6px;
                    transition: all 0.3s ease;
                  }
                  .convert_container {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background-color: white;
                    box-sizing: border-box;
                    border-top: 1px solid rgba(0, 40, 75, 0.1);
                    box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
                    transition: all 1s ease-out;
                    animation: animateSelectWrap 0.2s linear;

                    .convert_content {
                      display: flex;
                      justify-content: center;
                      padding: 20px 0;

                      .convert_content_item {
                        &:not(:last-of-type) {
                          margin-right: 20px;
                          padding-right: 20px;
                          border-right: 1px solid #e3e7e3;
                        }
                        .convert_title {
                          color: #353535;
                          font-family: Poppins;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 150% */
                          margin-top: 20px;
                        }
                        .feature_list {
                          margin-top: 24px;
                          display: grid;
                          grid-template-rows: repeat(2, 1fr);
                          grid-auto-flow: column;
                          row-gap: 8px;

                          .feature_list_item {
                            width: 188px;
                            padding: 8px 12px;
                            border-radius: 8px;
                            cursor: pointer;
                            &:hover {
                              border-radius: 4px;
                              background: #e4e3fd;
                            }
                            .feature_item_img {
                              width: 24px;
                              height: 24px;
                              margin-right: 10px;
                            }
                            .feature_text {
                              display: flex;
                              align-items: center;
                              color: #353535;
                              font-family: Poppins;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 24px; /* 171.429% */
                              img {
                                width: 40px;
                                height: 18px;
                                margin-left: 10px;
                              }
                            }
                            .feature_des {
                              margin-top: 8px;
                              color: #353535;
                              font-family: Poppins;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 18px; /* 150% */
                            }
                          }
                        }
                      }
                    }
                  }
                }

                a {
                  color: #353535;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 150% */
                  margin-right: 10px;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
    .h5_nav_bar {
      display: none;
    }
  }

  @keyframes animateSelectWrap {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
@media (max-width: 980px) {
  .nav_comtainer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1004 !important;
    .nav_wrap {
      display: none;
    }
    .h5_nav_bar {
      display: block;
    }
  }
}
</style>