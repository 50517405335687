<template>
  <!-- 首页banner 组件 -->
  <div class="banner_container">
    <img class="banner_background" :src="$getFile(ish5?content.backgroundImageH5:content.backgroundImage)" />
    <div class="context_wrap">
      <div class="banner_title" v-html="content.title"></div>
      <div class="banner_desc">
        {{ content.des }}
      </div>
    </div>
    <div class="banner_img">
      <img :src="$getFile(`${content.bannerImage}`)">
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils'
export default {
  name: 'Banner',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ish5() {
      return utils.isMobile()
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    height: 660px;
    .banner_background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: -1;
    }
    .context_wrap {
      width: 710px;
      .banner_title {
        font-family: Poppins;
        font-size: 58px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        color: #000;
        margin-bottom: 32px;
        ::v-deep .ttt {
          background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .banner_desc {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    .banner_img {
      img {
        width: 642px;
        // height: 522px;
      }
    }
  }
}

@media (max-width: 980px) {
  .banner_container {
    position: relative;
    padding: 0.82rem 0.32rem 0.45rem;

    .banner_background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      z-index: -1;
    }
    .context_wrap {
      text-align: center;
      .banner_title {
        font-family: Poppins;
        font-size: 0.56rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        color: #000;
        margin-bottom: 0.24rem;
        ::v-deep .ttt {
          background: linear-gradient(91deg, #5145ff 6.03%, #9a39ff 94.59%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .banner_desc {
        color: #000;
        font-family: Poppins;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 150% */
      }
    }

    .banner_img {
      width: 6.5rem;
      margin: 0 auto;
      margin-top: 0.6rem;
      img {
        width: 6.5rem;
        // height: 5.2rem;
      }
    }
  }
}
</style>