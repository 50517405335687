<template>
  <!-- 首页用户评论组件 -->
  <div class="discuss-ppt">
    <h2>{{ content.title }}</h2>
    <div id="water" ref="water" class="discuss-list">
      <div v-for="(item, idx) in content.list" :key="idx" class="discuss-item">
        <div class="discuss-header">
          <div class="row">
            <img :src="$getFile(item.img)" alt="" />
            <div>
              <h3>{{ item.name }}</h3>
              <span>{{ item.occupation }}</span>
            </div>
          </div>
        </div>
        <p>{{ item.des }}</p>
      </div>
    </div>
    <div class="discuss-mobile">
      <div class="discuss-mobile-swiper-box">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, idx) in content.list" :key="idx">
            <div class="discuss-item">
              <div class="discuss-item-header">
                <img class="discuss-img" :src="$getFile(item.img)" alt="" />
                <div class="discuss-user">
                  <h3>{{ item.name }}</h3>
                  <span>{{ item.occupation }}</span>
                </div>
              </div>
              <p>{{ item.des }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Water from '@/common/waterfall'
export default {
  name: 'DiscussPPT',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
    itemWidth: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: 280
    },
    itemColumn: {
      type: Number,
      default: 4
    },
    itemGap: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        loopAdditionalSlides: 1,
        slidesOffsetBefore: -10,
        slidesOffsetAfter: 30,
        autoplay: {
          delay: 3000000,
          disableOnInteraction: false
        },
      }
    }
  },
  watch: {
    i18nLocale(newVal, oldVal) {
      this.waterFall.init()
      this.waterFall.render()
    }
  },
  mounted() {
    this.$nextTick((e) => {
      this.waterFall = new Water({
        el: this.$refs.water,
        width: this.itemWidth,
        column: this.itemColumn,
        gap: this.itemGap
      })
    })
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .discuss-ppt {
    width: 100%;
    max-width: 1580px;
    height: 780px;
    margin: 0 auto;
    padding-bottom: 60px;
    position: relative;
    border-radius: 32px;
    margin-top: 68px;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 1580px;
      height: 584px;
      background: var(--theme-primary-ppt-wrap);
      border-radius: 3px;
      bottom: 0;
      z-index: -1;
    }

    h2 {
      font-size: 36px;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: 52px;
      text-align: center;
      margin-bottom: 12px;
    }

    .discuss-list {
      width: 1180px;
      margin: 0 auto;
      margin-top: 60px;
      position: relative;

      .discuss-item {
        width: 280px;
        padding: 24px;
        background: #ffffff;
        box-shadow: 1px 4px 14px 3px rgb(166 161 219 / 15%);
        border-radius: 16px;
        box-sizing: border-box;
        position: absolute;

        .discuss-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .discuss-img {
            width: 56px;
            height: 56px;
            margin-right: 8px;
          }

          img {
            width: 56px;
            height: 56px;
            margin-right: 8px;
          }

          h3 {
            font-size: 16px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: var(--theme-primary-black);
            line-height: 24px;
          }

          span {
            font-size: 14px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #82808e;
            line-height: 20px;
          }
        }

        p {
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: 24px;
        }
      }
    }

    .discuss-mobile {
      display: none;
      overflow: hidden;
    }
  }
}

@media (max-width: 980px) {
  .discuss-ppt {
    background: var(--theme-primary);
    padding: 1.4rem 0;

    h2 {
      font-size: .48rem;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: .72rem;
      margin-bottom: .2rem;
      text-align: center;
    }

    .discuss-list {
      position: relative;
      display: none;
      margin: 0 auto;
      margin-top: .6rem;

      .discuss-item {
        padding: .24rem;
        background: #ffffff;
        box-shadow: 0px 4px 14px 3px rgb(166 161 219 / 12%);
        border-radius: 16px;
        box-sizing: border-box;
        position: absolute;

        .discuss-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: .1rem;
          background: rebeccapurple;

          .discuss-img {
            width: .56rem;
            height: .56rem;
            margin-right: 8px;
          }

          img {
            width: .56rem;
            height: .56rem;
            margin-right: .1rem;
          }


          h3 {
            font-size: 16px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: var(--theme-primary-black);
          }

          span {
            font-size: 14px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #82808e;
          }
        }

        p {
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: 24px;
        }
      }
    }

    .discuss-mobile {
      margin-top: 0.6rem;

      .discuss-mobile-swiper-box {
        .discuss-item {
          width: 100%;
          min-height: 4.8rem;
          margin: 0 .2rem;
          padding: .35rem .3rem;
          background-color: white;
          border-radius: 0.3rem;
          box-sizing: border-box;

          .discuss-item-header {
            display: flex;
            align-items: center;

            .discuss-img {
              width: 0.8rem;
              height: 0.8rem;
              margin-right: 0.16rem;
              flex: none;
            }

            .discuss-user {
              display: flex;
              flex-direction: column;
            }

            h3 {
              font-size: 0.32rem;
              color: #05021e;
              line-height: 0.48rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
            }

            span {
              color: #83808e;
              font-size: 0.28rem;
              line-height: 0.42rem;
            }
          }

          p {
            color: #05021e;
            font-size: 0.28rem;
            line-height: 0.42rem;
            font-family: Poppins-Regular, Poppins;
            margin-top: 0.26rem;
          }
        }
      }
    }
  }
}
</style>