<template>
  <!-- H5登录弹窗 -->
  <div class="mobile_header_container">
    <div class="mobile_kefu">
      <kefu ref="kefu" :hideKefu="content.hideKefu || false" />
    </div>

    <div class="mobile_header">
      <a class="nav_logo" href="/">
        <img src="../assets/images/newimages/logo.svg" />
      </a>
      <img class="open_icon" :src="$getFile(showRightPop?'ui/icon-top_close.webp':'ui/icon-top_open.webp')" @click="handleSlide(!showRightPop)" />
    </div>
    <div class="mobile_slide_container" :style="{opacity: showRightPop ? 1 : 0,height: showRightPop ? '100vh' : 0}">
      <div class="slider">

        <div class="slider_content">

          <div class="content_item_user" @click="accoutOpen = !accoutOpen;" v-if="userState">
            <div class="user_tools_title" :class="[accoutOpen?'user_tools_title_active':'']">
              <img class="user_icon" :src="$getFile('newimages/user_icon.svg')" />
              {{userState}}
            </div>

            <div class="user_feature_list" :style="{height:accoutOpen?'100%':'0'}" v-show="accoutOpen">
              <div class="user_feature_item" @click="logoutBtn('account')">
                <img class="user_icon" :src="$getFile('newimages/user_ii.svg')" />
                {{loginInfo.loginAccount || "account"}}
              </div>

              <div class="user_feature_item" @click="logoutBtn('exit')">
                <img class="user_icon" :src="$getFile('newimages/user_out.svg')" />
                {{loginInfo.loginExit || "exit"}}
              </div>
            </div>
          </div>

          <div class="content_item" v-for="(items, index) in menuList" :key="index">
            <div class="tools_title" :class="[items.isOpen?'tools_title_active':'']" @click="openSubItem(items,index)">
              {{ items.title }} {{ afterBrand}}
              <img :class="['icon_arrow',items.isOpen?'icon_arrow_active':'']" src="../assets/images/newimages/arrow_icon.svg" alt="">

            </div>
            <div class="feature_list" :style="{height:items.isOpen?'100%':'0'}" v-show="items.isOpen">
              <a :href="item.url" class="feature_item" v-for="(item, index) in items.list" :key="index">
                <div class="context_wrap">
                  <div class="feature_text">
                    {{ item.title}}<img class="tag" src="../assets/images/newimages/hot_icon.svg" v-if="item.tag" />
                  </div>
                  <div class="feature_desc">{{ item.des}}</div>
                </div>
              </a>
            </div>
          </div>
          <div class="content_item">
            <div class="tools_title" @click="openKefu">
              <span>{{ $t('support') }}</span>
            </div>
          </div>
        </div>

        <LoginWrapNew v-if="!userState" />
      </div>
    </div>
  </div>
</template>

<script>
import kefu from "./ui/kefu.vue";
import { mapState } from "vuex";

export default {
  components: { kefu },

  props: {
    afterBrand: {
      type: String,
      default: "",
    },
    beforeBrand: {
      type: String,
      default: "",
    },
    content: {
      typeof: Object,
      default: () => { },
    },
    siteType: {
      type: Number,
      default: 1,
    },
    navBarList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      menuList: [],
      showRightPop: false,
      scrollPosition: 0,
      accoutOpen: false,
      userState: ''
    };
  },
  computed: {
    ...mapState(["loginInfo"]),
    routerPath() {
      return this.$route.path;
    },
  },
  mounted() {
    this.userState = localStorage.getItem("user_email");
    let items = [];
    Object.keys(this.navBarList).forEach((res) => {
      this.navBarList[res].list = this.navBarList[res].list.map((item) => {
        if (Number(item.type) === Number(this.siteType)) {
          item.url = window.location.origin;
        }
        return item;
      });
      items.push(this.navBarList[res]);
    });
    this.menuList = items;
  },
  watch: {
    routerPath: {
      handler(newVal, oldVal) {
        this.showRightPop = false;
      },
      immediate: true,
    },
    showRightPop: {
      handler(newVal, oldVal) {
        if (newVal) {
          const $body = document.querySelector("body");
          const scrollPosition = window.pageYOffset;
          $body.style.overflow = "hidden";
          $body.style.position = "fixed";
          $body.style.top = `-${scrollPosition}px`;
          $body.style.width = "100%";
          this.scrollPosition = scrollPosition;
        } else {
          const $body = document.querySelector("body");
          $body.style.removeProperty("overflow");
          $body.style.removeProperty("position");
          $body.style.removeProperty("top");
          $body.style.removeProperty("width");
          window.scrollTo(0, this.scrollPosition);
        }
      },
    },
  },
  methods: {
    openKefu() {
      this.$refs.kefu.openPoup();
    },
    logoutBtn(type) {
      if (type === "account") {
        const oldUrl = window.location.href;
        const newUrl = window.location.origin + "/sub/account";
        if (newUrl !== oldUrl) window.open(newUrl, "_self");
      } else {
        this.$api
          .Logout()
          .then((result) => {
            this.$store.commit("REMOVE_USER_INFO");
            this.userState = localStorage.getItem("user_email");

            if (this.$route.path !== "/") this.$router.push({ path: "/" });
          })
          .catch((err) => {
            console.log(err, '失败 >>>')
          });
      }
      this.showRightPop = false;
    },
    handleSlide(type) {
      if (type) {
        this.userState = localStorage.getItem("user_email");
        this.showRightPop = !this.showRightPop;
        // this.scrollFill = 'shadow'
        this.$emit("open", this.showRightPop);
      } else {
        this.userState = localStorage.getItem("user_email");
        this.scrollFill = "";
        this.showRightPop = false;
        this.$emit("close", this.showRightPop);
      }
    },
    openSubItem(item, key) {
      this.accoutOpen = false
      const isO = item.isOpen
      this.menuList = this.menuList.map((res, index) => {
        res.isOpen = false;
        if (index === key) {
          res.isOpen = !isO;
        }
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 981px) {
  .mobile_header_container {
    height: 1.16rem;
  }
  .mobile_kefu {
    :deep(.kefu-title) {
      display: none;
    }
  }
  .mobile_header {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 7.5rem;
    height: 1.16rem;
    padding: 0 0.32rem;
    border-bottom: 1px solid #efefef;
    background: #fff;
    box-sizing: border-box;
    z-index: 900;

    .open_icon {
      width: 0.4rem;
      height: 0.4rem;
    }
    .nav_logo {
      img {
        width: 2.45rem;
        height: 0.68rem;
      }
    }
  }

  .mobile_slide_container {
    position: fixed;
    left: 0;
    right: 0;
    top: 1.16rem;
    z-index: 1013;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;

    .slider {
      position: absolute;
      right: 0;
      width: 7.5rem;
      height: 100%;
      padding: 0.36rem;
      box-sizing: border-box;
      background-color: white;
      transition: all 0.3s ease-in-out;
      z-index: 1012;
      box-shadow: 0rem 0.02rem 0.24rem 0rem rgba(156, 155, 166, 0.4);

      .slider_content {
        padding-bottom: 2rem;
        .content_item {
          position: relative;
          border-radius: 0.08rem;
          border: 1px solid #dad9da;
          margin-bottom: 0.24rem;
          .tools_title {
            display: block;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.3rem;
            font-size: 0.28rem;
            font-family: Inter;
            font-weight: 400;
            color: #05021e;

            .icon_arrow {
              width: 0.22rem;
              height: 0.28rem;
              transform: rotate(-90deg);
              transition: all 0.3s ease-in-out;
            }

            .icon_arrow_active {
              transform: rotate(90deg);
            }

            .user_icon {
              width: 0.68rem;
              height: 0.68rem;
            }
          }
          .tools_title_active {
            border-radius: 0.06rem 0.06rem 0 0;
            background: #e4e3fd;
          }
          .feature_list {
            width: 100%;
            height: 0;
            transition: height 0.3s ease-in-out;
            .feature_item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0.3rem 0;
              margin: 0 0.25rem;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
              &:last-child {
                border-bottom: none;
                padding-bottom: 0.5rem;
              }
              .context_wrap {
                .feature_text {
                  display: flex;
                  align-items: center;
                  color: #353535;
                  font-family: Poppins;
                  font-size: 0.28rem;
                  font-style: normal;
                  font-weight: 600;
                  text-transform: capitalize;
                  img {
                    width: 0.78rem;
                    height: 0.36rem;
                    margin-left: 0.12rem;
                  }
                }
                .feature_desc {
                  color: #353535;
                  font-family: Poppins;
                  font-size: 0.24rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 0.32rem; /* 133.333% */
                  text-transform: capitalize;
                  margin-top: 0.32rem;
                }
              }
            }
          }
        }

        .content_item_user {
          position: relative;
          border-radius: 0.08rem;
          border: 1px solid #dad9da;
          margin-bottom: 0.24rem;

          .user_tools_title {
            display: block;
            display: flex;
            align-items: center;
            padding: 0.17rem 0.3rem;
            font-size: 0.28rem;
            font-family: Inter;
            font-weight: 400;
            color: #05021e;

            .user_icon {
              width: 0.68rem;
              height: 0.68rem;
              margin-right: 0.27rem;
            }
          }

          .user_tools_title_active {
            border-radius: 0.06rem 0.06rem 0 0;
            background: #e4e3fd;
          }
          .user_feature_list {
            .user_feature_item {
              display: flex;
              align-items: center;
              padding: 0.3rem 0;
              margin: 0 0.25rem;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
              color: #353535;
              font-family: Poppins;
              font-size: 0.32rem;
              font-style: normal;
              font-weight: 400;
              line-height: 0.48rem; /* 150% */
              &:last-child {
                border-bottom: none;
              }

              .user_icon {
                width: 0.4rem;
                margin-right: 0.12rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>